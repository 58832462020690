import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-timeline-dot',
  templateUrl: './timeline-dot.component.html',
  styleUrls: ['./timeline-dot.component.scss']
})
export class TimelineDotComponent {
  @Input() icon!: string;
  @Input() label!: string;
  @Input() color!: string;

  get style() {
    return {
      color: this.color
    };
  }
}
