import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Environment, Role } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RolesService {
  constructor(private readonly httpClient: HttpClient, private readonly storage: LocalStorageService, @Inject('environment') private readonly environment: Environment) {
  }

  getRoleByBusinessProfileId(businessProfileId: string): Observable<Role> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomEncoder(),
      fromObject: {
        businessProfileId
      }
    });
    return this.httpClient.get<{
      content: Role[]
    }>(`${this.environment.api.url}${this.environment.api.endpoints.roles}`, { params }).pipe(
      map(({ content }) => content && content[0]),
      filter((role: Role) => !!role),
      tap((role: Role) => {
        this.storage.set(LocalStorageKeys.STORAGE_PRIVILEGES_KEY, JSON.stringify(role.rights));
      })
    );
  }
}
