import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { WidgetEvent, WidgetInstance } from '@iot-platform/models/widgets';

@Component({
  selector: 'iot-platform-ui-widgets-container',
  templateUrl: './widgets-container.component.html',
  styleUrls: ['./widgets-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetsContainerComponent implements OnChanges {
  @Input() public widgets: WidgetInstance[] = [];

  @Output() public dispatchEvent: EventEmitter<WidgetEvent> = new EventEmitter<WidgetEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets.currentValue) {
      this.widgets = this.widgets.sort((widgetA, widgetB) => (widgetA.data.name as string).localeCompare(widgetB.data.name));
    }
  }
}
