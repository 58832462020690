import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(private readonly httpClient: HttpClient) {}

  getBuildInfo(): Observable<{
    version: string;
  }> {
    return this.httpClient
      .get<{
        version: string;
      }>(`./assets/data/build.json`)
      .pipe(
        map((data) => data)
      );
  }
}
