import { Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  standalone: true,
  imports: [FlexLayoutModule],
  selector: 'iot-platform-ui-cards-container',
  templateUrl: './cards-container.component.html',
  styleUrls: ['./cards-container.component.scss']
})
export class CardsContainerComponent {
  @Input() horizontalLayout = false;
  @Input() virtualScroll = false;
}
