import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Filter, FilterDynamicListMultipleSelectsInput, FilterDynamicListMultipleSelectsOptions } from '@iot-platform/models/common';
import { DynamicListFieldService } from '@iot-platform/shared/services';

import { Observable, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-ui-dynamic-list-field',
  templateUrl: './dynamic-list-field-multiple-select.component.html',
  styleUrls: ['./dynamic-list-field-multiple-selects.component.scss']
})
export class DynamicListFieldMultipleSelectsComponent implements OnInit, OnDestroy {
  dynamicListForm: UntypedFormGroup;
  subs: Subscription[] = [];
  showLoader = true;

  categoryList: any = [];
  labelList: any = [];
  category: any;

  @Input() data!: FilterDynamicListMultipleSelectsOptions;
  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize = 0;
  @Input() maxFilters = 20;

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  constructor(
    private readonly dynamicListFieldService: DynamicListFieldService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initFormGroup();
    this.setDynamicLists();
    if (this.data.multiSelect) {
      this.subs.push(
        this.currentFilters$.pipe(filter((filters) => filters.length === 0)).subscribe(() => {
          this.dynamicListForm.reset();
        })
      );
    }
  }

  initFormGroup(): void {
    const group = {};
    this.data.inputs.forEach((input: FilterDynamicListMultipleSelectsInput) => {
      group[input.id] = new UntypedFormControl(null);
    });

    this.dynamicListForm = new UntypedFormGroup(group);
  }

  setDynamicLists(): void {
    this.data.inputs?.forEach((input: FilterDynamicListMultipleSelectsInput) => {
      if (input.url) {
        this.categoryList = input;
        this.subs.push(
          this.dynamicListFieldService
            .getDynamicList(input.url, input.sortMethod)
            .pipe(
              finalize(() => {
                this.ref.detectChanges();
              })
            )
            .subscribe(
              (dynamicList: Array<any>) => {
                input.display = dynamicList;
                this.showLoader = false;
                if (this.data.multiSelect) {
                  this.categoryList = input;
                }
              },
              () => {
                this.showLoader = false;
              },
              () => {
                this.showLoader = false;
              }
            )
        );
      }
    });
  }

  getParentLabel(input: FilterDynamicListMultipleSelectsInput, previousLabel: string): string {
    previousLabel = this.dynamicListForm.get(input.id).value[input.labelToDisplay].concat(' - ', previousLabel);

    if (input.parentListId !== null) {
      return this.getParentLabel(
        this.data.inputs.find((parent) => parent.id === input.parentListId),
        previousLabel
      );
    } else {
      return previousLabel.substring(0, previousLabel.length - 3);
    }
  }

  onSelectionChange(input: FilterDynamicListMultipleSelectsInput, value: any): void {
    if (input.filterBy) {
      const f: Filter = {};
      f.value = value[input.filterBy];
      f.label = this.getParentLabel(input, '');
      f.criteriaKey = this.data.criteriaKey;
      f.criteriaLabel = this.data.criteriaLabel;

      this.dispatchFilterValue.emit(f);
      this.dynamicListForm.controls[input.id].reset();
    } else {
      const childInput = this.data.inputs.find((i) => i.parentListId === input.id);
      childInput.display = this.dynamicListFieldService.sortDynamicList(value[childInput.parentListProperty], childInput.sortMethod, 'object');

      if (this.data.multiSelect) {
        this.category = value;
        this.labelList = childInput;
      }
    }
  }

  onMultiSelectionChange(value: any): void {
    const f: Filter = {};
    f.criteriaKey = this.data.criteriaKey;
    f.criteriaLabel = this.data.criteriaLabel;
    f.value = value[this.labelList.filterBy];
    f.label = `${this.category.name} - ${value.name}`;
    this.dispatchFilterValue.emit(f);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => s && s.unsubscribe());
  }
}
