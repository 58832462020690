import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-detail-simple-card',
  templateUrl: './detail-simple-card.component.html',
  styleUrls: ['./detail-simple-card.component.scss']
})
export class DetailSimpleCardComponent {
  @Input() value: string;
}
