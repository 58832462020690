import { Injectable } from '@angular/core';
import { AuthBusinessProfilesApiActions } from '@iot-platform/auth';
import { Role } from '@iot-platform/models/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../services/notification.service';
import { PermissionsService } from '../../../../services/permissions.service';

import { RolesService } from '../../services';
import { RolesActions } from '../actions';

@Injectable()
export class RolesEffects {
  getRoleByBusinessProfileId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getRoleByBusinessProfileId),
      switchMap(({ businessProfileId }) =>
        this.rolesService.getRoleByBusinessProfileId(businessProfileId).pipe(
          map((role: Role) => RolesActions.getRoleByBusinessProfileIdSuccess({ role })),
          catchError((error) => of(RolesActions.getRoleByBusinessProfileIdFailure({ error })))
        )
      )
    )
  );

  getRoleByBusinessProfileIdSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesActions.getRoleByBusinessProfileIdSuccess),
        tap(() => {
          this.permissionsService.init();
        })
      ),
    { dispatch: false }
  );

  selectBusinessProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      map(({ selectedBusinessProfile }) => RolesActions.getRoleByBusinessProfileId({ businessProfileId: selectedBusinessProfile.id }))
    )
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesActions.getRoleByBusinessProfileIdFailure),
        tap((action) => {
          this.notificationService.hideLoader();
          this.notificationService.displayError(action.type);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly rolesService: RolesService,
    private readonly permissionsService: PermissionsService,
    private readonly notificationService: NotificationService
  ) {}
}
