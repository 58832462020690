import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-user-thumbnail',
  templateUrl: 'user-thumbnail.component.html',
  styleUrls: ['user-thumbnail.component.scss']
})
export class UserThumbnailComponent {
  @Input() src = '';
  @Input() content = '';

  constructor(public ref: ElementRef) {}

  getSubstr(): string {
    const toSplit = this.content.replace('  ', ' ');
    const splittedString: Array<string> = toSplit.split(' ');
    let substring = '';
    if (splittedString.length === 1) {
      if (splittedString[0][0]) {
        substring += splittedString[0][0];
      }
      if (splittedString[0][1]) {
        substring += splittedString[0][1];
      }
    } else {
      if (splittedString[0][0]) {
        substring += splittedString[0][0];
      }
      if (splittedString[1][0]) {
        substring += splittedString[1][0];
      }
    }
    return substring;
  }
}
