import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoles from './roles.reducer';

export const authFeatureKey = 'qlixbiAuth';

export interface AuthState {
  [fromRoles.rolesFeatureKey]: fromRoles.State;
}

export interface State {
  [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromRoles.rolesFeatureKey]: fromRoles.reducer
  })(state, action);
}

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);
export const selectRolesState = createSelector(selectAuthState, (state: AuthState) => state[fromRoles.rolesFeatureKey]);

export const selectLoading = createSelector(selectRolesState, fromRoles.selectLoading);
export const selectCurrentRole = createSelector(selectRolesState, fromRoles.selectCurrentRole);
