import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [NgIf, TranslateModule, FlexLayoutModule, MatIconModule, MatButtonModule, MatTooltipModule],
  selector: 'iot-platform-ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  @Input() title!: string;
  @Input() hasButton = false;
  @Input() hasToolbar = false;
  @Input() buttonTooltip!: string;
  @Input() withCustomAction = false;
  @Input() icon = 'add';

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
