<div class="detail-card">
  <ul class="detail-card-list">
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.NAME' | translate }}" *ngIf="extraFill">{{ site?.name | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ENTITY' | translate }}" *ngIf="extraFill">{{ site?.entity.name | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ID' | translate }}">{{ site?.businessId | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.TYPE' | translate }}">{{ site?.type | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_1' | translate }}">{{ site?.address.address1 | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.ADDRESS_2' | translate }}">{{ site?.address.address2 | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_CARD_COMPONENT.CITY' | translate }}">{{ site?.address | infoDisplay: 'brackets':'city':'zipCode' }}</li>
  </ul>
</div>