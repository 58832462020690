import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Environment } from "@iot-platform/models/common";

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(@Inject(DOCUMENT) private readonly document: Document, @Inject('environment') private readonly environment: Environment) {
  }

  signInSso(): void {
    this.document.location.href = this.environment.cognito.ssoUrl.replace('[origin]', window.location.origin).replace('[clientId]', this.environment.cognito.clientId);
  }
}
