<iot-platform-ui-panel
        (clicked)='manageTags.emit()'
        [buttonTooltip]='tooltip | translate'
        [hasButton]='canCreateOrUpdateTag'
        [title]='title | translate'
>
    <mat-chip-list panelContent>
        <ng-container *ngIf='!loading'>
            <iot-platform-ui-chip
                    *ngFor='let tag of tags'
                    [chipButton]='{ display: false }'
                    [chip]='tag'
                    [isTag]='true'
                    [ngClass]='{ invisible: loading, visible: !loading }'
            >
            </iot-platform-ui-chip>
        </ng-container>
    </mat-chip-list>
    <iot-platform-ui-card-loader *ngIf='loading' [backgroundClass]="'tag-loader info-page'"
                                 panelGrid></iot-platform-ui-card-loader>
</iot-platform-ui-panel>
