import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarButtonComponent } from './toolbar-button.component';

@NgModule({
  declarations: [ToolbarButtonComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatTooltipModule, MatIconModule, MatMenuModule],
  exports: [ToolbarButtonComponent]
})
export class ToolbarButtonModule {}
