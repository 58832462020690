import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppVersionService } from '../../../../libs/qlixbi-ui/src/lib/services';
import { UserProfileService } from '../../../../libs/users/src/lib/features/profile/services/user-profile.service';

@Component({
  selector: 'qlixbi-webapp',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'qlixbi-webapp';
  defaultTheme = 'default-theme';
  appVersion = '';

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly appVersionService: AppVersionService,
    private readonly userProfileService: UserProfileService
  ) {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svgs/iot4bos-icon-set.svg'));
    [
      'connected-device',
      'full',
      'partially full',
      'reserve',
      'empty',
      'open-external',
      'ic_web',
      'order',
      'red-dot',
      'grey-dot',
      'notifications',
      'notifications-paused',
      'notifications-acknowledge',
      'occurence',
      'open-in-new',
    ].map((icon) => this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/svgs/${icon}.svg`)));

    this.appVersionService.getBuildInfo().subscribe((buildInfo) => (this.appVersion = buildInfo.version));

    this.userProfileService.setThemeOverlay(this.defaultTheme);
  }
}
