import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';

import { IconModule } from '@iot-platform/shared/components';

import { TranslateModule } from '@ngx-translate/core';
import { TagCategoryListByConceptComponent } from './tag-category-list-by-concept.component';
import { TagLabelListComponent } from './tag-label-list/tag-label-list.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    TranslateModule,
    FlexModule,
    ReactiveFormsModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [TagCategoryListByConceptComponent, TagLabelListComponent],
  exports: [TagCategoryListByConceptComponent, TagLabelListComponent]
})
export class TagCategoryListByConceptModule {}
