<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'USERS.PROFILE_INFO_FORM.UI.POPUP_TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="exit()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <form [formGroup]="userPreferencesForm" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field class="" fxFlex color="accent">
          <mat-select [placeholder]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate" formControlName="appLanguage" ngDefaultControl matInput required>
            <mat-option *ngFor="let language of availableLanguages" [value]="language">
              {{ 'USERS.PROFILE_PREFERENCES.LANGUAGES.' + language | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="" fxFlex color="accent">
          <mat-select [placeholder]="'USERS.PROFILE_PREFERENCES.DATE_FORMAT' | translate" formControlName="appDateFormats" ngDefaultControl matInput required>
            <mat-option *ngFor="let dateFormat of dateFormatList" [value]="dateFormat">{{ today | dateFormat: dateFormat.momentFullFormat }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="" fxFlex color="accent">
          <mat-select
            [placeholder]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMAT' | translate"
            formControlName="appNumberFormat"
            ngDefaultControl
            matInput
            required
          >
            <mat-option *ngFor="let numberFormat of numberFormatList" [value]="numberFormat">
              {{ 'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.' + numberFormat | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="" fxFlex color="accent">
          <mat-select
            [placeholder]="'USERS.PROFILE_PREFERENCES.NUMBER_OF_DECIMALS' | translate"
            formControlName="appNumberOfDecimals"
            ngDefaultControl
            matInput
            required
          >
            <mat-option *ngFor="let numberOfDecimals of numberOfDecimalsList" [value]="numberOfDecimals">{{ numberOfDecimals }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="40px">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="14px" *ngIf="data?.withNotifications">
          <span>{{ 'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate }}</span>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="user-profile-preferences-notification-checkbox">
            <mat-checkbox color="accent" formControlName="notificationSMS" ngDefaultControl (ngModelChange)="onModelChange()">
              {{ 'USERS.PROFILE_PREFERENCES.SMS' | translate }}
            </mat-checkbox>
            <mat-checkbox color="accent" formControlName="notificationEmail" ngDefaultControl (ngModelChange)="onModelChange()">
              {{ 'USERS.PROFILE_PREFERENCES.EMAIL' | translate }}
            </mat-checkbox>
            <mat-checkbox color="accent" formControlName="notificationSecondaryEmail" ngDefaultControl (ngModelChange)="onModelChange()">
              {{ 'USERS.PROFILE_PREFERENCES.SECONDARYEMAIL' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="exit()">{{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!userPreferencesForm.valid || !userPreferencesForm.touched" (click)="save()">
      {{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
