import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-site-detail-basic-info',
  templateUrl: './site-detail-basic-info.component.html',
  styleUrls: ['./site-detail-basic-info.component.scss']
})
export class SiteDetailBasicInfoComponent {
  @Input() site: Site | null = null;
  @Input() displayMode: 'vertical' | 'horizontal' | 'default' = 'default';

  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();
}
