import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { PopupComponent } from './popup.component';

@NgModule({
    imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, FlexLayoutModule],
    declarations: [PopupComponent],
    exports: []
})
export class PopupModule {}
