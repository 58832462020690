import { Role } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';
import { RolesActions } from '../actions';

export const rolesFeatureKey = 'rolesFeature';

export interface State {
  loading: boolean;
  error: unknown;
  role: Role;
}

export const initialState: State = {
  loading: false,
  error: null,
  role: null
};

export const reducer = createReducer(
  initialState,
  on(RolesActions.getRoleByBusinessProfileId, (state: State): State => ({
      ...state,
      loading: true
    })),
  on(RolesActions.getRoleByBusinessProfileIdSuccess, (state: State, { role }): State => ({
      ...state,
      loading: false,
      role
    })),
  on(
    RolesActions.getRoleByBusinessProfileIdFailure,
    (state: State, { error }): State => ({
      ...state,
      error,
      loading: false
    })
  )
);

export const selectLoading = (state: State) => state.loading;
export const selectCurrentRole = (state: State) => state.role;
