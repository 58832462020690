import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { ChipModule } from '../chip/chip.module';
import { PanelComponent } from '../panel';
import { TagsListComponent } from './tags-list.component';

const COMPONENTS = [TagsListComponent];

@NgModule({
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  imports: [CommonModule, MatChipsModule, TranslateModule, PanelComponent, CardLoaderModule, ChipModule]
})
export class TagsListModule {}
