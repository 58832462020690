import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-timeline-list-item',
  templateUrl: './timeline-list-item.component.html',
  styleUrls: ['./timeline-list-item.component.scss']
})
export class TimelineListItemComponent {
  @Input() icon!: string;
  @Input() timestamp!: string;
  @Input() label!: string;
  @Input() description!: string;
  @Input() cssClassName!: string;
}
