<div class="contact-card--container" [class.minimalDisplay]="minimalDisplay">
  <div class="full-height" fxLayout="row">
    <div class="contact-card--col" fxFlex="40px">
      <button mat-mini-fab class="user-initials" color="accent">{{ initials }}</button>
    </div>
    <div class="contact-card--col" fxLayout="column" fxLayoutAlign="space-between start">
      <div class="contact-card--info" fxLayout="column" fxLayoutGap="4px">
        <div class="contact-card--info__name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <h3
            [matTooltip]="contact.firstname + ' ' + contact.lastname | infoDisplay"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="above"
            class="contact-card--title fw-600"
          >
            {{ contact.firstname + ' ' + contact.lastname | infoDisplay }}
          </h3>
          <div class="contact-card--job capitalize-first-letter">{{ contact.function | infoDisplay }}</div>
        </div>
        <div class="phone" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>phone</mat-icon>
          <div class="contact-card--item">{{ contact | infoDisplay: 'pipe' : 'phone' : 'mobile' }}</div>
        </div>
        <div class="email full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>mail_outlined</mat-icon>
          <span *ngIf="!contact.email">-</span>
          <a
            class="contact-card--item"
            [matTooltip]="contact.email"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="above"
            href="mailto:{{ contact.email }}"
            >{{ contact.email }}</a
          >
        </div>
      </div>
      <div class="contact-card--notification-export-info" fxLayoutGap="4px" *ngIf="!minimalDisplay">
        <span [ngClass]="{ clickable: !!contact.associatedNotifications?.length }" (click)="onNotificationsClicked()"
          >{{ 'CONTACT_CARD.NOTIFICATION_INFO' | translate }}{{ contact.associatedNotifications?.length | infoDisplay }}</span
        >
        <span>|</span>
        <span [ngClass]="{ clickable: !!contact.associatedExportSpreadsheets?.length }" (click)="onExportSpreadsheetsClicked()"
          >{{ 'CONTACT_CARD.EXPORT_SPREADSHEET_INFO' | translate }}{{ contact.associatedExportSpreadsheets?.length | infoDisplay }}</span
        >
      </div>
      <div class="contact-card--buttons app-round-button" *ngIf="canUpdate || canDelete">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="canUpdate" (click)="edit()">{{ 'SITES.CONTACT_FORM.EDIT' | translate }}</button>
          <ng-container *ngIf="!minimalDisplay">
            <button mat-menu-item *ngIf="canUpdate" (click)="configureNotif()">{{ 'SITES.CONTACT_FORM.CONFIGURE_NOTIF' | translate }}</button>
            <button mat-menu-item *ngIf="canUpdate" (click)="configureExport()">{{ 'SITES.CONTACT_FORM.CONFIGURE_EXPORT' | translate }}</button>
          </ng-container>
          <button mat-menu-item *ngIf="canDelete" (click)="onDelete()">{{ 'SITES.CONTACT_FORM.DELETE' | translate }}</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
