import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, Graph } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GraphsService {
  constructor(@Inject('environment') private readonly environment: Environment, public http: HttpClient) {}

  public getAll(siteId: string): Observable<Graph[]> {
    return this.http.get<Graph[]>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/graphs`).pipe(map((data: any) => data.content));
  }

  public getById(siteId: string, graphId: string): Observable<Graph> {
    return this.http.get<Graph>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/graphs/${graphId}`);
  }

  public post(siteId: string, graph: Graph): Observable<Graph> {
    return this.http.post<Graph>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/graphs`, graph);
  }

  public patch(siteId: string, graph: Graph): Observable<Graph> {
    return this.http.patch<Graph>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/graphs/${graph.id}`, graph);
  }

  public delete(siteId: string, graphId: string): Observable<any> {
    return this.http.delete<Graph>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/graphs/${graphId}`);
  }

  public getGraphsByAssetVariableId(assetVariableId: string, siteId: string): Observable<Graph[]> {
    return this.getAll(siteId).pipe(map((graphs) => (graphs || []).filter((graph: Graph) => (graph.variables as any[]).some((v) => v.id === assetVariableId))));
  }
}
