<!-- <div class="spinner-x" *ngIf="value !== 0"> -->
    <div class="spinner-value">{{ progress$ | async }}%</div>
    <mat-progress-spinner 
        [color]="color" 
        [diameter]="diameter" 
        [mode]="mode" 
        [strokeWidth]="strokeWidth" 
        [value]="progress$ | async">
    </mat-progress-spinner>
<!-- </div> -->
