import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@iot-platform/models/common';
import { Device, TimelineEvent, TimelineTarget, TimelineType } from '@iot-platform/models/qlixbi';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimelineService {
  constructor(
    private readonly http: HttpClient,
    @Inject('environment') private readonly environment: Environment
  ) {}

  get(id: string, target: TimelineTarget, type: TimelineType): Observable<TimelineEvent[]> {
    return this.http.get<TimelineEvent[]>(
      `${this.environment.api.url}${this.environment.api.endpoints.timeline}?id=${id}&objectType=${target}&timeline=${type}`
    );
  }

  saveMaintenanceEvent(
    device: Device,
    decision: 'release' | 'escalate' | 'scrap',
    data?: {
      [key: string]: unknown;
    }
  ): Observable<TimelineEvent> {
    const payload = {
      deviceId: device.id,
      operation: decision
    };
    if (data) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      payload['timelineEvent'] = data;
    }
    return this.http.post<TimelineEvent>(`${this.environment.api.url}${this.environment.api.endpoints.endOfMaintenance}`, payload);
  }
}
