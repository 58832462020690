import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-asset-details-basic-info',
  templateUrl: './asset-details-basic-info.component.html',
  styleUrls: ['./asset-details-basic-info.component.scss']
})
export class AssetDetailsBasicInfoComponent {
  @Input() asset: Asset | null = null;
  @Input() displayMode: 'vertical' | 'horizontal' | 'default' = 'default';
  @Output() selectAsset: EventEmitter<Asset> = new EventEmitter<Asset>();
}
