import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-detail-panel',
  templateUrl: './detail-panel.component.html',
  styleUrls: ['./detail-panel.component.scss']
})
export class DetailPanelComponent {
  @Input() title: string;
  @Input() hasButton: boolean;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.clickEvent.emit();
  }
}
