<div class="simple-card {{cssClasses}}" [ngClass]="{'simple-card--with-icon': icon || image }">
    <div fxLayout="row" fxLayout.lt-md="column">
        <div class='simple-card__image-container' *ngIf="image || icon" fxLayout="column wrap"
             fxLayoutAlign="center center" fxFlex="150px" fxFlex.lt-md="100%">
            <img class='simple-card__image' *ngIf="image" [src]="image" alt=""/>
            <mat-icon class='simple-card__icon' *ngIf="icon" [svgIcon]='icon' color='accent'></mat-icon>
        </div>
        <div class="simple-card__content full-width" fxLayout="column" fxFlex="100%">
            <div fxLayout="row" fxLayoutAlign="start stretch">
                <ng-content select="[cardContent]"></ng-content>
                <div class="simple-card__menu app-round-button" *ngIf="withMenu">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let action of actions">
                            <button mat-menu-item *ngIf="action?.enabled"
                                    (click)="onDispatchEvent(action)">{{ action?.label | translate  | uppercase }}</button>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
