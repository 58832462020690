import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApplyAuthorizationDirective } from './apply-authorization.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ApplyAuthorizationDirective],
  exports: [ApplyAuthorizationDirective],
  providers: []
})
export class ApplyAuthorizationModule {}
