import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@iot-platform/auth';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./containers/app-shell/app-shell.component').then((mod) => mod.AppShellComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'reports',
        loadChildren: () => import('./features/reports/reports.module').then((module) => module.ReportsModule)
      },
      {
        path: 'sites',
        loadChildren: () => import('./features/sites/sites.module').then((module) => module.SitesModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./features/orders/orders.module').then((module) => module.OrdersModule)
      },
      {
        path: 'assets',
        loadChildren: () => import('./features/assets/assets.module').then((module) => module.AssetsModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('./features/devices/devices.module').then((module) => module.DevicesModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./features/events/events.module').then((module) => module.EventsModule)
      },
      {
        path: 'logs',
        loadChildren: () => import('./features/logs/logs.module').then((module) => module.LogsModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./features/devices/containers/device-maintenance').then((module) => module.MAINTENANCE_ROUTES)
      },
      { path: '', pathMatch: 'full', redirectTo: '/maintenance' }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class QlixbiUiModule {}
