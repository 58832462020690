import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectChange as MatSelectChange, MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { Device, VariablesTableFilters } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FlexLayoutModule,
    TranslateModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule
  ],
  selector: 'iot-platform-ui-device-info-toolbar',
  templateUrl: './device-info-toolbar.component.html',
  styleUrls: ['./device-info-toolbar.component.scss']
})
export class DeviceInfoToolbarComponent implements OnInit, OnChanges {
  @Input() device: Device;
  @Input() deviceVariableGroups: { key: string; value: string }[] = [];
  @Input() deviceVariableFiles: { key: string; value: string }[] = [];
  @Input() variableUnits: string[] = [];
  @Input() filters: Partial<VariablesTableFilters>;
  @Input() total: number;
  @Input() totalFiltered: number;
  @Input() displayLoader: boolean;
  @Input() withNameFilter = false;
  @Input() withDescriptionFilter = false;
  @Input() withUnitFilter = false;
  @Input() withGroupFilter = false;
  @Input() withFileFilter = false;
  @Input() withLinkedFilter = false;

  @Output() applyFilters: EventEmitter<Partial<VariablesTableFilters>> = new EventEmitter<Partial<VariablesTableFilters>>();
  @Output() filterNameValueChange: EventEmitter<string> = new EventEmitter();
  @Output() filterDescriptionValueChange: EventEmitter<string> = new EventEmitter();
  @Output() changeGroup: EventEmitter<{ key: string; value: string }> = new EventEmitter();
  @Output() changeFile: EventEmitter<{ key: string; value: string }> = new EventEmitter();
  @Output() clearFilters: EventEmitter<string> = new EventEmitter();
  @Output() manageColumns: EventEmitter<boolean> = new EventEmitter(false);
  toolbarForm: UntypedFormGroup;

  ngOnInit() {
    this.toolbarForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.filters ? this.filters.name : ''),
      linked: new UntypedFormControl(this.filters ? this.filters.linked : null),
      description: new UntypedFormControl(this.filters ? this.filters.description : ''),
      group: new UntypedFormControl(this.filters && this.filters.group ? this.filters.group.key : null),
      file: new UntypedFormControl(this.filters && this.filters.file ? this.filters.file.key : null),
      unit: new UntypedFormControl(this.filters ? this.filters.unit : null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.toolbarForm && changes.hasOwnProperty('filters') && changes.filters.currentValue.group) {
      this.toolbarForm.get('group').setValue(changes.filters.currentValue.group ? changes.filters.currentValue.group.key : null);
    }

    if (this.toolbarForm && changes.hasOwnProperty('filters') && changes.filters.currentValue.file) {
      this.toolbarForm.get('file').setValue(changes.filters.currentValue.file ? changes.filters.currentValue.file.key : null);
    }
  }

  onFilterNameValueChange(event) {
    this.applyFilters.emit({ ...this.filters, name: event.target.value });
  }

  onFilterDescriptionValueChange(event) {
    this.applyFilters.emit({ ...this.filters, description: event.target.value });
  }

  onGroupSelectionChange(event: MatSelectChange) {
    const groupUsedToFilter = this.deviceVariableGroups.find((g) => g.key === event.value);
    this.applyFilters.emit({ ...this.filters, group: groupUsedToFilter?.value !== undefined ? groupUsedToFilter : null });
  }

  onFileSelectionChange(event: MatSelectChange) {
    const fileUsedToFilter = this.deviceVariableFiles.find((f) => f.key === event.value);
    this.applyFilters.emit({ ...this.filters, file: fileUsedToFilter?.value !== undefined ? fileUsedToFilter : null });
  }

  onLinkedSelectionChange(event: MatSelectChange) {
    this.applyFilters.emit({ ...this.filters, linked: event.value === true || event.value === false ? event.value : null });
  }

  onUnitSelectionChange(event: MatSelectChange) {
    this.applyFilters.emit({ ...this.filters, unit: event.value });
  }

  onManageColumns() {
    this.manageColumns.emit(true);
  }

  clear() {
    this.toolbarForm.reset();
    this.clearFilters.emit('');
  }

  apply() {
    this.toolbarForm.get('name').setValue(this.toolbarForm.get('name').value);
    this.toolbarForm.get('description').setValue(this.toolbarForm.get('description').value);
    this.toolbarForm.get('group').setValue(this.toolbarForm.get('group').value);
    this.toolbarForm.get('file').setValue(this.toolbarForm.get('file').value);
    this.toolbarForm.get('linked').setValue(this.toolbarForm.get('linked').value);
    this.toolbarForm.get('unit').setValue(this.toolbarForm.get('unit').value);
  }
}
