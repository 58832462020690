import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SpinnerBackgroundComponent } from './spinner-background.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [SpinnerBackgroundComponent],
  declarations: [SpinnerBackgroundComponent]
})
export class SpinnerBackgroundModule {}
