import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule as BaseAuthModule } from '@iot-platform/auth';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HomeComponent } from './containers/home/home.component';
import { LoginCallbackComponent } from './containers/login-callback/login-callback.component';
import { LoginComponent } from './containers/login/login.component';
import { RolesEffects } from './state/effects';
import * as fromAuth from './state/reducers';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'callback',
        component: LoginCallbackComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    SharedModule,
    BaseAuthModule.forRoot(),
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([RolesEffects])
  ],
  declarations: [HomeComponent, LoginCallbackComponent, LoginComponent]
})
export class AuthModule {}
