import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavMenuBlock, SidenavMenuItem } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-sidenav-items',
  templateUrl: './sidenav-items.component.html',
  styleUrls: ['./sidenav-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemsComponent {
  static currentMenuTrigger: MatMenuTrigger | null;
  static timeoutIdMouseLeave: number;
  static timeoutIdMouseEnter: number;

  @Input() item!: SidenavMenuItem;

  url$: Observable<string>;

  constructor(public router: Router) {
    this.url$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  handleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.item.handleClick?.();
    SidenavItemsComponent.currentMenuTrigger?.closeMenu();
  }

  navigate(): void {
    if (!this.item.externalLink) {
      this.router.navigate([this.item.path]);
    } else {
      window.open(this.item.path, '_blank');
    }
  }

  mouseEnter(event: MouseEvent, trigger?: MatMenuTrigger): void {
    event.stopPropagation();
    window.clearTimeout(SidenavItemsComponent.timeoutIdMouseEnter);
    window.clearTimeout(SidenavItemsComponent.timeoutIdMouseLeave);
    if (SidenavItemsComponent.currentMenuTrigger && this.item.level === 0) {
      SidenavItemsComponent.currentMenuTrigger.closeMenu();
      SidenavItemsComponent.currentMenuTrigger = null;
    }
    if (trigger && this.item.hasChildren) {
      SidenavItemsComponent.currentMenuTrigger = trigger;
      SidenavItemsComponent.timeoutIdMouseEnter = window.setTimeout(() => {
        trigger.openMenu();
      }, 400);
    }
  }

  mouseLeave(event: MouseEvent): void {
    event.stopPropagation();
    if (SidenavItemsComponent.currentMenuTrigger) {
      window.clearTimeout(SidenavItemsComponent.timeoutIdMouseEnter);
      SidenavItemsComponent.timeoutIdMouseLeave = window.setTimeout(() => {
        SidenavItemsComponent.currentMenuTrigger?.closeMenu();
        SidenavItemsComponent.currentMenuTrigger = null;
      }, 2000);
    }
  }

  trackById(index: number, item: SidenavMenuBlock) {
    return item.id;
  }
}
