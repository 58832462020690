import { Component, Input } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss']
})
export class DetailCardComponent {
  @Input() site: Site;
  @Input() extraFill = false;
}
