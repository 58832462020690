import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromAuth } from '@iot-platform/auth';
import { UserAccount } from '@iot-platform/models/common';

import { Store } from '@ngrx/store';

import { switchMap, take } from 'rxjs/operators';
import { fromUserPreferences } from '../../../preferences';
import { PreferencesActions } from '../../../preferences/state/actions';

import { UserProfileFormComponent } from '../../components/user-profile-form/user-profile-form.component';
import { UserProfilePreferencesFormComponent } from '../../components/user-profile-preferences-form/user-profile-preferences-form.component';
import { ProfileActions } from '../../state/actions';
import * as fromUserProfile from '../../state/reducers';

@Component({
  selector: 'iot-platform-users-user-profile-shell',
  templateUrl: './user-profile-shell.component.html',
  styleUrls: ['./user-profile-shell.component.scss']
})
export class UserProfileShellComponent {
  currentUser$ = this.store.select(fromUserProfile.getCurrentUser);
  preferences$ = this.store.select(fromUserPreferences.getPreferences);
  loading$ = this.store.select(fromUserProfile.getUserProfileLoading);
  withNotifications = true;

  constructor(
    protected store: Store,
    protected dialog: MatDialog
  ) {
    this.store.select(fromAuth.selectSelectedBusinessProfileForAccount).subscribe((bp) => {
      if (bp) {
        this.store.dispatch(ProfileActions.loadUserProfile());
        this.store.dispatch(PreferencesActions.loadUserPreferences());
      }
    });
  }

  editUserProfile() {
    this.currentUser$
      .pipe(
        take(1),
        switchMap((user) => this.dialog
            .open(UserProfileFormComponent, {
              width: '700px',
              disableClose: true,
              data: { user }
            })
            .afterClosed())
      )
      .subscribe((updatedUser) => {
        if (updatedUser) {
          this.store.dispatch(ProfileActions.saveUserProfile({ userToUpdate: updatedUser }));
        }
      });
  }

  changeUserPreferences() {
    this.currentUser$
      .pipe(
        take(1),
        switchMap((user) => this.dialog
            .open(UserProfilePreferencesFormComponent, {
              width: '800px',
              disableClose: true,
              data: {
                user,
                withNotifications: this.withNotifications
              }
            })
            .afterClosed())
      )
      .subscribe((updatedUser: UserAccount) => {
        if (updatedUser) {
          this.store.dispatch(PreferencesActions.saveUserPreferences({ user: updatedUser, preferences: updatedUser.preferences }));
        }
      });
  }
}
