import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum MediaWatcherBreakpoints {
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  Unknown = 'Unknown'
}

@Injectable({
  providedIn: 'root'
})
export class MediaWatcherService {
  mediaChange$: Observable<{ matchingAliases: string[]; matchingQueries: { [key: string]: string } }>;
  private readonly displayNameMap = new Map([
    [Breakpoints.XSmall, MediaWatcherBreakpoints.XSmall],
    [Breakpoints.Small, MediaWatcherBreakpoints.Small],
    [Breakpoints.Medium, MediaWatcherBreakpoints.Medium],
    [Breakpoints.Large, MediaWatcherBreakpoints.Large],
    [Breakpoints.XLarge, MediaWatcherBreakpoints.XLarge]
  ]);

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.mediaChange$ = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(
        map(({ breakpoints }) => {
          const matchingAliases: MediaWatcherBreakpoints[] = [];
          const matchingQueries: { [key: string]: string } = {};

          for (const query of Object.keys(breakpoints)) {
            if (breakpoints[query]) {
              const currentScreenSize: MediaWatcherBreakpoints = this.displayNameMap.get(query) ?? MediaWatcherBreakpoints.Unknown;
              matchingAliases.push(currentScreenSize);
              matchingQueries[currentScreenSize] = query;
            }
          }
          return {
            matchingAliases,
            matchingQueries
          };
        })
      );
  }

  get isSmallScreen$(): Observable<boolean> {
    return this.mediaChange$.pipe(
      map(({ matchingAliases }) => [MediaWatcherBreakpoints.XSmall, MediaWatcherBreakpoints.Small].some((q) => matchingAliases.includes(q)))
    );
  }

  onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(query);
  }
}
