import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PopupComponent } from '../../popup/popup.component';

import { EditTagLabelDialogComponent } from '../edit-tag-label-dialog/edit-tag-label-dialog.component';
import { TagEditorService } from '../tag-editor.service';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatChipsModule,
    UpperCasePipe
  ],
  selector: 'iot-platform-ui-tag-editor-form',
  templateUrl: './tag-editor-form.component.html',
  styleUrls: ['./tag-editor-form.component.scss']
})
export class TagEditorFormComponent implements OnInit, OnChanges {
  @Input() tagCategory: TagCategory;
  @Input() colors: string[] = [];
  @Input() mode: 'ADD' | 'UPDATE';

  @Output() changeValue: EventEmitter<void> = new EventEmitter();
  @Output() save: EventEmitter<TagCategory> = new EventEmitter();
  @Output() remove: EventEmitter<TagCategory> = new EventEmitter();

  tagsForm: UntypedFormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  tagNameMaxLength = 20;
  maximumTagPerCategory = 25;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  defaultCategoryName: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly tagEditorService: TagEditorService
  ) {}

  get colorFormControl(): AbstractControl {
    return this.tagsForm.get('colorFormControl');
  }

  get nameFormControl(): AbstractControl {
    return this.tagsForm.get('nameFormControl');
  }

  get labelsFormControl(): AbstractControl {
    return this.tagsForm.get('labelsFormControl');
  }

  ngOnInit() {
    this.buildForm();
    if (this.tagCategory) {
      this.defaultCategoryName = this.tagCategory.name;
    }
    this.tagsForm.valueChanges.subscribe(() => this.changeValue.emit());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colors && changes.colors.currentValue && changes.colors.currentValue.indexOf(this.colorFormControl.value) === -1) {
      this.colorFormControl.setValue(null);
      this.colorFormControl.updateValueAndValidity();
      this.colorFormControl.markAsDirty();
    }
  }

  checkCategoryName(categoryName: UntypedFormControl) {
    if (!this.tagsForm || !categoryName.dirty) {
      return of(null);
    }

    return this.tagEditorService.getTagCategoryNameDuplicates(this.tagCategory.entityId, categoryName.value).pipe(
      map((isDuplicate: boolean) => (this.defaultCategoryName === categoryName.value ? null : isDuplicate ? { duplicate: true } : null)),
      take(1)
    );
  }

  checkDuplicateLabel(label: string): boolean {
    return !!this.tagCategory.labels.filter((l) => l.name.trim().toLowerCase() === label.trim().toLowerCase()).length;
  }

  addLabel(category: TagCategory, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (category.labels.length === this.maximumTagPerCategory) {
      return;
    }

    if (value.trim() !== '') {
      const duplicates = category.labels.filter((l) => l.name.trim().toLowerCase() === value.trim().toLowerCase());

      if (duplicates.length) {
        return;
      }

      if (category && category.labels) {
        category.labels.push({ name: value.trim() });
      } else {
        category.labels = [];
        category.labels.push({ name: value.trim() });
      }
    }

    if (input) {
      input.value = '';
    }

    this.labelsFormControl.updateValueAndValidity();
    this.labelsFormControl.markAsDirty();
  }

  onEditLabel(label: TagLabel) {
    if (label.id) {
      this.dialog
        .open(EditTagLabelDialogComponent, {
          data: {
            label,
            tagNameMaxLength: this.tagNameMaxLength,
            categoryLabels: this.tagCategory.labels
          },
          width: '350px',
          disableClose: true
        })
        .afterClosed()
        .subscribe((newLabel: TagLabel) => {
          if (newLabel && newLabel.name !== label.name) {
            this.tagCategory.labels.splice(
              this.tagCategory.labels.findIndex((oldLabel) => oldLabel.id === label.id),
              1,
              newLabel
            );
            this.labelsFormControl.updateValueAndValidity();
            this.labelsFormControl.markAsDirty();
          }
        });
    }
  }

  removeLabel(category: TagCategory, label: TagLabel): void {
    const index = category.labels.indexOf(label);

    if (index >= 0) {
      category.labels.splice(index, 1);
    }
    this.labelsFormControl.updateValueAndValidity();
    this.labelsFormControl.markAsDirty();
  }

  onRemove(category) {
    this.confirmDeletion(category);
  }

  onSave(category: TagCategory) {
    const newCat: TagCategory = {
      ...category,
      color: this.colorFormControl.value,
      name: this.nameFormControl.value.trim(),
      labels: [...category.labels] // this.labelsFormControl.value
    };
    this.save.emit(newCat);

    if (this.mode === 'ADD') {
      // this.tagsForm.reset();
      this.tagsForm.clearValidators();
      this.tagCategory = { ...this.tagCategory, color: '', labels: [], name: '' };
      this.buildForm();
    }
  }

  private buildForm() {
    this.tagsForm = new UntypedFormGroup({
      colorFormControl: new UntypedFormControl(this.tagCategory?.color ?? null, [Validators.required]),
      nameFormControl: new UntypedFormControl(
        this.tagCategory?.name ?? null,
        [Validators.required, Validators.maxLength(this.tagNameMaxLength)],
        [this.checkCategoryName.bind(this)]
      ),
      labelsFormControl: new UntypedFormControl(this.tagCategory?.labels ?? [], [Validators.required, Validators.maxLength(this.maximumTagPerCategory)])
    });
  }

  private confirmDeletion(category: TagCategory) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: category.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.remove.emit(category);
      }
    });
  }
}
