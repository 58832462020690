<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ getLabels$('title') | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="contactForm" class="p-20">
      <div class="container">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20">
          <span class="site-contact_form--notification-label">{{ 'SITES.CONTACT_FORM.NOTIFICATION_SUPPORTS' | translate }}</span>
          <mat-slide-toggle color="accent" formControlName="notificationViaEmail"
            ><mat-icon [matTooltip]="'IOT_DICTIONARY.EMAIL' | translate" matTooltipClass="regular_tooltip">mail</mat-icon></mat-slide-toggle
          >
          <mat-slide-toggle color="accent" formControlName="notificationViaSms"
            ><mat-icon [matTooltip]="'IOT_DICTIONARY.SMS' | translate" matTooltipClass="regular_tooltip">sms</mat-icon></mat-slide-toggle
          >
        </div>
        <mat-form-field color="accent">
          <input
            matInput
            formControlName="firstname"
            #firstnameInput
            required
            maxlength="50"
            pattern="\S.*"
            placeholder="{{ 'SITES.CONTACT_FORM.FIRST_NAME' | translate }}"
          />
          <mat-error *ngIf="firstname.invalid && (firstname.errors?.maxlength || firstname.errors?.pattern)">{{
            'SITES.CONTACT_FORM.ERROR_MESSAGE.REQUIRED' | translate
          }}</mat-error>
          <mat-hint align="end">{{ firstnameInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            matInput
            formControlName="lastname"
            #lastnameInput
            required
            maxlength="50"
            pattern="\S.*"
            placeholder="{{ 'SITES.CONTACT_FORM.LAST_NAME' | translate }}"
          />
          <mat-error *ngIf="lastname.invalid && (lastname.errors?.maxlength || lastname.errors?.pattern)">{{
            'SITES.CONTACT_FORM.ERROR_MESSAGE.REQUIRED' | translate
          }}</mat-error>
          <mat-hint align="end">{{ lastnameInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>

        <mat-form-field color="accent">
          <input #jobTitleInput matInput formControlName="function" maxlength="40" placeholder="{{ 'SITES.CONTACT_FORM.FUNCTION' | translate }}" />
          <mat-hint align="end">{{ jobTitleInput.value?.length || 0 }}/40</mat-hint>
        </mat-form-field>

        <mat-form-field color="accent">
          <input #emailInput matInput formControlName="email" maxlength="50" placeholder="{{ 'SITES.CONTACT_FORM.EMAIL' | translate }}" />
          <mat-error *ngIf="email.invalid">{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.NOT_EMAIL' | translate }}</mat-error>
          <mat-error *ngIf="email.invalid && email.errors?.emailDuplicate">{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.DUPLICATE_EMAIL' | translate }}</mat-error>
          <mat-hint align="end">{{ emailInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>

        <mat-form-field color="accent">
          <input #phoneInput matInput formControlName="phone" maxlength="20" placeholder="{{ 'SITES.CONTACT_FORM.PHONE' | translate }}" />
          <mat-error *ngIf="phone.invalid">{{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.NOT_PHONE' | translate }}</mat-error>
          <mat-hint align="end">{{ phoneInput.value?.length || 0 }}/20</mat-hint>
          <mat-icon matTooltip="{{ 'SITES.CONTACT_FORM.INFO_MESSAGE.PHONE_FORMAT' | translate }}" matSuffix>info_outline</mat-icon>
        </mat-form-field>

        <mat-form-field color="accent">
          <textarea #commentInput matInput formControlName="comment" maxlength="300" placeholder="{{ 'SITES.CONTACT_FORM.COMMENT' | translate }}"></textarea>
          <mat-hint align="end">{{ commentInput.value?.length || 0 }}/300</mat-hint>
        </mat-form-field>
        <p
          class="site-contact-form--email-sms-warning"
          [ngClass]="{ 'layout-visible': !email.value && !phone.value, 'layout-invisible': !!email.value || !!phone.value }"
        >
          {{ 'SITES.CONTACT_FORM.ERROR_MESSAGE.EMAIL_PHONE_NOT_BOTH_EMPTY' | translate }}
        </p>
        <p class="dialog-form-tips">{{ 'SITES.CONTACT_FORM.MANDATORY_FIELDS' | translate }}</p>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions fxLayout="row" fxLayoutAlign="end center" class="dialog-card-actions">
    <button mat-button class="button-regular" (click)="close()">{{ 'SITES.CONTACT_FORM.CANCEL' | translate }}</button>
    <button
      mat-raised-button
      class="button-regular"
      color="accent"
      [disabled]="isSaveButtonDisabled$ | async"
      (click)="save()"
    >
      {{ getLabels$('action') | async }}
    </button>
  </mat-card-actions>
</mat-card>
