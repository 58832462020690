import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { ChipModule } from '../chip/chip.module';
import { DetailPopupCardsModule } from '../detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { AssetDetailsBasicInfoComponent } from './asset-details-basic-info/asset-details-basic-info.component';
import { AssetDetailsFollowedVariablesComponent } from './asset-details-followed-variables/asset-details-followed-variables.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    IotPlatformPipesModule,
    DetailPopupModule,
    DetailPopupCardsModule,
    CardLoaderModule,
    ChipModule,
    MatChipsModule
  ],
  declarations: [AssetDetailsBasicInfoComponent, AssetDetailsFollowedVariablesComponent],
  exports: [AssetDetailsBasicInfoComponent, AssetDetailsFollowedVariablesComponent]
})
export class AssetDetailsModule {}
