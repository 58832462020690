import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ChipModule } from '../chip/chip.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { FilterEngineModule } from '../filter-engine/filter-engine.module';
import { FavoriteViewFormComponent } from './favorite-view-form.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    IotPlatformPipesModule,
    ChipModule,
    ColorPickerModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    DetailPopupModule,
    MatTabsModule,
    MatSelectModule,
    FilterEngineModule,
    MatProgressSpinnerModule
  ],
  declarations: [FavoriteViewFormComponent],
  exports: [FavoriteViewFormComponent]
})
export class FavoriteViewFormModule {}
