import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserByIdService } from './user-by-id.service';

@Component({
  selector: 'iot-platform-ui-user-by-id',
  templateUrl: './user-by-id.component.html',
  styleUrls: ['./user-by-id.component.scss']
})
export class UserByIdComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userId!: string;
  @Input() label!: string;

  username!: string;
  destroyed$ = new Subject();

  constructor(private readonly userByIdService: UserByIdService) {}

  ngOnInit(): void {
    this.getUser();
  }

  ngOnChanges(): void {
    this.getUser();
  }

  getUser(): void {
    if (this.userId) {
      this.userByIdService
        .getUserById(this.userId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((username) => (this.username = username));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
