import { Injectable } from '@angular/core';
import { fromAuth as fromBaseAuth } from '@iot-platform/auth';
import { BusinessProfile, Role, UserAccount } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import * as fromAuth from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  loading$: Observable<boolean> = this.store.select(fromBaseAuth.selectAuthPage);
  loggedIn$: Observable<boolean> = this.store.select(fromBaseAuth.selectLoggedIn);
  currentUser$: Observable<UserAccount> = this.store.select(fromBaseAuth.selectCurrentUser);
  businessProfiles$: Observable<BusinessProfile[]> = this.store.select(fromBaseAuth.selectBusinessProfilesForAccount);
  selectedBusinessProfile$: Observable<BusinessProfile> = this.store.select(fromBaseAuth.selectSelectedBusinessProfileForAccount);
  isBusinessProfileChanged$: Observable<boolean>;
  currentRole$: Observable<Role> = this.store.select(fromAuth.selectCurrentRole);

  constructor(private readonly store: Store, private readonly authService: AuthService) {
    this.listenForBusinessProfileChanges();
  }

  listenForBusinessProfileChanges(): void {
    this.isBusinessProfileChanged$ = combineLatest([
      this.selectedBusinessProfile$,
      this.store.select(fromBaseAuth.selectSelectedBusinessProfileForAccount)
    ]).pipe(
      map(([oldBp, newBp]) => oldBp && newBp && oldBp.id !== newBp.id)
    );
  }

  signInSso(): void {
    this.authService.signInSso();
  }
}
