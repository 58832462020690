import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoginCallbackComponent as BaseLoginCallbackComponent } from '@iot-platform/auth';
import { NotificationService } from '@iot-platform/notification';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: `
    <div>{{ 'LOGIN.AUTH_IN_PROGRESS' | translate }}</div>`,
  selector: 'qlixbi-login-callback',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginCallbackComponent extends BaseLoginCallbackComponent {
  constructor(
    protected notificationService: NotificationService,
    protected authService: AuthService,
    protected translate: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store
  ) {
    super(notificationService, authService, translate, route, router, store);
  }
}
