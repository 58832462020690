import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { SidenavMenuBlock } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthorizationConcept, AuthorizationType } from '../authorization.types';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(@Inject('environment') private environment, public http: HttpClient, public storage: LocalStorageService) {}

  public getAuthorizations(): Observable<any> {
    return this.http.get<any>(`${this.environment.api.url}/account/privileges`).pipe(
      tap((privileges) => {
        this.storage.set(LocalStorageKeys.STORAGE_PRIVILEGES_KEY, JSON.stringify(privileges.concepts));
      }),
      map((response) => response.concepts)
    );
  }

  public getAuthorizationsByBusinessProfile(businessProfileId: string): Observable<any> {
    const headers = new HttpHeaders().set('business-profile-id', businessProfileId);
    return this.http.get<any>(`${this.environment.api.url}/account/privileges`, { headers }).pipe(
      map((response) => response.concepts)
    );
  }

  applyAuthorization(concept: AuthorizationConcept, authorizationType: AuthorizationType): boolean {
    const authz = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_PRIVILEGES_KEY));
    if (authz && authz[concept.toUpperCase()]) {
      return authz[concept.toUpperCase()].includes(authorizationType.toLowerCase());
    }
    return false;
  }

  getNavigationTree(navTree: SidenavMenuBlock[]): SidenavMenuBlock[] {
    navTree.forEach((block) => {
      block.display = false;
      block.items.forEach((item) => {
        if (item.concepts) {
          item.display = this.processConditionsOnConcepts(item.concepts);
          if (item.display) {
            block.display = true;
          }
          if (item.submenu) {
            item.submenu = this.getNavigationTree(item.submenu);
          }
        } else {
          item.display = true;
          block.display = true;
        }
      });
    });

    return navTree;
  }

  processConditionsOnConcepts(concepts: { name: string; right: string }[][]): boolean {
    if (!concepts || (concepts && concepts.length === 0)) {
      return true;
    } else {
      return concepts.reduce((andConditionArrayResult, orConditionArray) => {
        const orConditionResult = orConditionArray.reduce((conditionResult, condition) => {
          conditionResult = conditionResult || this.applyAuthorization(AuthorizationConcept[condition.name], AuthorizationType[condition.right]);
          return conditionResult;
        }, false);

        return andConditionArrayResult && orConditionResult;
      }, true);
    }
  }
}
