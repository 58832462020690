import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { TranslateModule } from '@ngx-translate/core';
import { UserRelatedBusinessProfilesComponent } from './user-related-business-profiles.component';

@NgModule({
  declarations: [UserRelatedBusinessProfilesComponent],
  exports: [UserRelatedBusinessProfilesComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule, MatListModule, MatIconModule]
})
export class UserRelatedBusinessProfilesModule {}
