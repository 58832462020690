import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error$: Subject<string | { code: string; error: string } | any> = new Subject<string | { code: string; error: string } | any>();
  success$: Subject<string> = new Subject<string>();
  loader$: Subject<boolean> = new BehaviorSubject<boolean>(false);

  displaySuccess(value: string): void {
    this.success$.next(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  displayError(error: string | { code: string; error: string } | any): void {
    this.error$.next(error);
  }

  displayLoader(value: boolean): void {
    this.loader$.next(value);
  }

  hideLoader(): void {
    this.loader$.next(false);
  }

  showLoader(): void {
    this.loader$.next(true);
  }
}
