import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from "@iot-platform/models/common";
import { Product } from '@iot-platform/models/qlixbi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(private readonly http: HttpClient, @Inject('environment') private readonly environment: Environment) {
  }

  get(id: string): Observable<Product> {
    return this.http.get<Product>(`${this.environment.api.url}${this.environment.api.endpoints.products}/${id}`);
  }

  getProductByCode(productCode: string): Observable<Product> {
    return this.http
      .get<{
        content: Product[]
      }>(`${this.environment.api.url}${this.environment.api.endpoints.products}?productIdentifier=${productCode}`)
      .pipe(map(({ content }) => (content && content.length ? content[0] : null)));
  }
}
