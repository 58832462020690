import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, combineAll, concatMap, filter, finalize, first, mergeMap, take } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { AuthApiActions, AuthPageActions } from './state/actions';
import * as fromAuth from './state/reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  tokenRefreshed$ = new BehaviorSubject<string | Record<string, unknown> | null>(null);
  private isRefreshingToken = false;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('/oauth2/token')) {
      return next.handle(request);
    }
    const validSession$ = of(
      this.store.select(fromAuth.selectLoggedIn),
      this.store.select(fromAuth.selectLoggedInWithSSO),
      this.store.select(fromAuth.selectIdToken),
      this.store.select(fromAuth.selectSelectedBusinessProfileId),
      this.store.select(fromAuth.selectRefreshToken),
      this.store.select(fromAuth.selectSsoTokenExpiresAt)
    ).pipe(combineAll());
    return validSession$.pipe(
      first<any>(),
      mergeMap(([loggedIn, loggedInWithSSO, idToken, businessProfileId, refreshToken, ssoTokenExpiresAt]) => {
        if (!loggedIn) {
          return next.handle(request);
        }
        if (loggedInWithSSO) {
          return next.handle(this.setRequestHearders(idToken, businessProfileId, request)).pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.error?.type === 'BusinessProfileNotCoveredByUserEntity') {
                  this.store.dispatch(AuthPageActions.signOut());
                }
                if (this.authService.ssoTokenExpired(ssoTokenExpiresAt) && err.status === 401) {
                  return this.handleRefreshSsoToken(refreshToken, businessProfileId as string, request, next);
                }
              }
              return throwError(err);
            })
          );
        } else {
          return next.handle(this.setRequestHearders(idToken, businessProfileId, request)).pipe(
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.error?.type === 'BusinessProfileNotCoveredByUserEntity') {
                  this.store.dispatch(AuthPageActions.signOut());
                }
                if (idToken && this.authService.tokenExpired(idToken) && err.status === 401) {
                  return this.handleRefreshToken(businessProfileId, request, next);
                }
              }
              return throwError(err);
            })
          );
        }
      })
    );
  }

  private setRequestHearders(idToken: string, businessProfileId: string, req: HttpRequest<any>): HttpRequest<any> {
    let request = req;
    if (!businessProfileId) {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`
        }
      });
    } else {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
          'business-profile-id': `${businessProfileId}`
        }
      });
    }
    return request;
  }

  private handleRefreshToken(businessProfileId: string, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isRefreshingToken) {
      return this.tokenRefreshed$.pipe(
        filter((t) => !!t),
        take(1),
        concatMap((idToken) => next.handle(this.setRequestHearders(idToken as string, businessProfileId, req)))
      );
    }

    this.isRefreshingToken = true;

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    this.tokenRefreshed$.next(null);

    // Do refresh token
    return this.refreshToken(businessProfileId, req, next);
  }

  private refreshToken(businessProfileId: string, req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      concatMap((refreshed: CognitoUser) => {
        const refreshedToken = refreshed.getSignInUserSession()?.getIdToken().getJwtToken() ?? '';
        this.store.dispatch(AuthApiActions.refreshTokenSuccess({ refreshed }));
        this.tokenRefreshed$.next(refreshedToken);
        return next.handle(this.setRequestHearders(refreshedToken, businessProfileId, req));
      }),
      catchError((error) => {
        // Disconnect the user and redirect to login page
        // in case we got an error while refreshing the token
        this.store.dispatch(AuthApiActions.refreshTokenFailure({ error }));
        this.store.dispatch(AuthPageActions.signOut());
        return EMPTY;
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      })
    );
  }

  private handleRefreshSsoToken(refreshToken: string, businessProfileId: string, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isRefreshingToken) {
      return this.tokenRefreshed$.pipe(
        filter((t) => !!t),
        take(1),
        concatMap((refreshed: { idToken: string }) => next.handle(this.setRequestHearders(refreshed.idToken, businessProfileId, req)))
      );
    }

    this.isRefreshingToken = true;

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    this.tokenRefreshed$.next(null);

    // Do refresh token
    return this.refreshSsoToken(refreshToken, businessProfileId, req, next);
  }

  private refreshSsoToken(refreshToken: string, businessProfileId: string, req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshSsoTokens(refreshToken).pipe(
      concatMap(({ idToken, accessToken, tokenType, expiresIn }) => {
        this.store.dispatch(AuthApiActions.refreshSsoTokensSuccess({ idToken, accessToken, tokenType, expiresIn }));
        this.tokenRefreshed$.next({
          idToken
        });
        return next.handle(this.setRequestHearders(idToken, businessProfileId, req));
      }),
      catchError((error) => {
        // Disconnect the user and redirect to login page
        // in case we got an error while refreshing the token
        this.store.dispatch(AuthApiActions.refreshSsoTokensFailure({ error }));
        this.store.dispatch(AuthPageActions.signOut());
        return EMPTY;
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      })
    );
  }
}
