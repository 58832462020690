import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class FormsHelpers {
  static isFormValid(form: UntypedFormGroup): boolean {
    form.markAllAsTouched();
    return (
      [...(Object.values(form.controls) ?? [])]?.every((f: AbstractControl) => {
        const disabled = f.disabled;
        if (disabled) {
          f.enable();
        }
        const valid = f.valid;
        if (disabled) {
          f.disable();
        }
        return valid;
      }) ?? false
    );
  }
}
