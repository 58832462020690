<div class="download-card" fxLayout="row" fxLayoutAlign="center center">
  <div class="download-card_box" fxLayout="column" fxLayoutAlign="center center" fxFlex="40%" fxFlex.lg="50%" fxFlex.md="70%" fxFlex.sm="100%" fxFlex.xs="100%">
    <mat-icon class="download-card_image">{{ isVisible ? 'file_download' : 'search' }}</mat-icon>
    <ng-container *ngIf="!loading">
      <label class="download-card_label">
        <span>{{ (isVisible ? 'REPORTS.EXPORT_DATA' : cardText) | translate }}</span>
      </label>
    </ng-container>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="20" color="accent"></mat-progress-spinner>
    <button *ngIf="isVisible && !loading" mat-button (click)="download.emit()">{{ buttonText | translate }}</button>
  </div>
</div>
