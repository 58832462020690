import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from "@iot-platform/models/common";
import { Entity } from '@iot-platform/models/qlixbi';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntityService {
  constructor(private readonly http: HttpClient, @Inject('environment') private readonly environment: Environment) {
  }

  getById(id: string, withChildren = false): Observable<Entity> {
    return this.http.get<Entity>(`${this.environment.api.url}${this.environment.api.endpoints.entities}/${id}?children=${withChildren}`);
  }
}
