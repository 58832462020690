import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tableRowHover]'
})
export class TableRowHoverDirective {
  private links;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.links = this.element.nativeElement.querySelectorAll('.mv-cell-link');
    this.links.forEach((link) => this.renderer.addClass(link, 'active'));
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.element.nativeElement.classList.contains('table-row-expanded')) {
      this.links.forEach((link) => this.renderer.removeClass(link, 'active'));
    }
  }

  @HostListener('click') onClick() {
    const rows = this.element.nativeElement.parentNode.querySelectorAll('.table-row-content');
    rows.forEach((row) => row.querySelectorAll('.mv-cell-link').forEach((link) => this.renderer.removeClass(link, 'active')));
    this.links.forEach((link) => this.renderer.addClass(link, 'active'));
  }
}
