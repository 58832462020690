import { ThemePalette } from '@angular/material/core';
import { IotToolbarButtonConfig, IotToolbarMenuButtonOption } from '../../models';
import { IotToolbarDispatchActionType } from './button-dispatch-action-type.enum';
import { IotToolbarButtonType } from './button-type.enum';

export interface IotToolbarButton<T extends IotToolbarButtonConfig> {
  config: T;
}

export class IotToolbarDefaultButtonConfig implements Partial<IotToolbarButtonConfig> {
  dispatchAction = { type: '', options: undefined };
  icon = '';
  svgIcon = '';
  tooltip = '';
  displayButton = true;
  color = 'accent' as ThemePalette;
  type = IotToolbarButtonType.REGULAR_BUTTON;
  disabled = false;
  order = 1;
  isToggleOn = false;
  toggleOnClass = '';
  toggleOffClass = '';
  removable = false;
}

/// //////////////////////////
// *** REGULAR CONFIGS *** //
/// //////////////////////////

export class IotToolbarRegularButtonConfig extends IotToolbarDefaultButtonConfig {
  constructor(customConfig: Partial<IotToolbarRegularButtonConfig>) {
    super();
    Object.keys(customConfig).forEach((property: string) => (this[property] = customConfig[property] ?? this[property]));
  }
}

const regularButtonBaseConfig: Partial<IotToolbarDefaultButtonConfig> = {
  displayButton: true,
  color: 'accent' as ThemePalette,
  disabled: false,
  type: IotToolbarButtonType.REGULAR_BUTTON,
  order: 1
};

const toggleButtonBaseConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  type: IotToolbarButtonType.TOGGLE_BUTTON,
  toggleOnClass: 'toggle-on',
  toggleOffClass: 'reverse-accent-button regular-round-button'
};

const addButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.ADD_ELEMENT },
  icon: 'add',
  tooltip: 'IOT_DICTIONARY.ADD'
};

const editButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.EDIT_ELEMENT },
  icon: 'edit',
  tooltip: 'IOT_DICTIONARY.EDIT'
};

const deleteButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.DELETE_ELEMENT },
  icon: 'delete',
  tooltip: 'IOT_DICTIONARY.DELETE'
};

const clearFiltersButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA },
  icon: 'delete_sweep',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS',
  removable: true
};

const exportButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.EXPORT_DATA },
  icon: 'get_app',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.EXPORT_DATA.DEFAULT'
};

const manageGridsButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  displayButton: false,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS },
  icon: 'format_list_numbered_rtl',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEFAULT'
};

const refreshButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.REFRESH_PAGE },
  icon: 'refresh',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.REFRESH.DEFAULT'
};

const toggleCatalogsButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...toggleButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.TOGGLE_CATALOGS },
  svgIcon: 'menu_book',
  tooltip: 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.DISPLAY_CATALOG_PANEL'
};

const toggleFilterEngineButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...toggleButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE },
  icon: 'filter_list',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.TOGGLE_FILTER_ENGINE.DEFAULT'
};

const manageProtocolsButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.OPEN_MANAGE_PROTOCOLS },
  svgIcon: 'escalation-protocol',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.MANAGE_PROTOCOLS'
};

const manageSubscribersButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.OPEN_MANAGE_SUBSCRIBERS },
  icon: 'group',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.MANAGE_SUBSCRIBERS'
};

const toggleSubscriptionButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...toggleButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.SUBSCRIPTION },
  icon: undefined,
  svgIcon: 'subscribe',
  toggleOnClass: 'subscribed',
  toggleOffClass: 'unsubscribed'
};

const configureSchedulerButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.CONFIGURE_SCHEDULER },
  icon: undefined,
  svgIcon: 'scheduler_toolbar',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_SCHEDULER'
};

const saveButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.SAVE },
  icon: 'save',
  tooltip: 'IOT_DICTIONARY.SAVE'
};

const selectDevicesForSchedulerButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.TOGGLE_DEVICES_SELECTION_FOR_SCHEDULER },
  icon: undefined,
  svgIcon: 'device',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.CHOOSE_DEVICES'
};

const cancelButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.CANCEL },
  icon: 'close',
  tooltip: 'IOT_DICTIONARY.CANCEL'
};

const schedulerLaunchNowButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.LAUNCH_NOW },
  icon: 'play_arrow_outlined',
  tooltip: 'IOT_DICTIONARY.LAUNCH_NOW'
};

const openHistoryButtonConfig: Partial<IotToolbarDefaultButtonConfig> = {
  ...regularButtonBaseConfig,
  dispatchAction: { options: undefined, type: IotToolbarDispatchActionType.OPEN_HISTORY },
  icon: 'history',
  tooltip: 'AUDIT_TRAIL.CTA.HISTORY'
};

//

export const ADD_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(addButtonConfig);
export const CANCEL_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(cancelButtonConfig);
export const CLEAR_FILTERS_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(clearFiltersButtonConfig);
export const CONFIGURE_SCHEDULER_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(configureSchedulerButtonConfig);
export const EDIT_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(editButtonConfig);
export const EXPORT_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(exportButtonConfig);
export const CONFIGURE_GRIDS_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(manageGridsButtonConfig);
export const REFRESH_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(refreshButtonConfig);
export const TOGGLE_CATALOGS_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(toggleCatalogsButtonConfig);
export const TOGGLE_FILTER_ENGINE_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(toggleFilterEngineButtonConfig);
export const MANAGE_PROTOCOLS_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(manageProtocolsButtonConfig);
export const MANAGE_SUBSCRIBERS_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(manageSubscribersButtonConfig);
export const SAVE_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(saveButtonConfig);
export const SELECT_DEVICES_FOR_SCHEDULER_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(selectDevicesForSchedulerButtonConfig);
export const TOGGLE_SUBSCRIPTION_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(toggleSubscriptionButtonConfig);
export const SCHEDULER_LAUNCH_NOW_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(schedulerLaunchNowButtonConfig);
export const DELETE_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(deleteButtonConfig);
export const OPEN_HISTORY_BUTTON_CONFIG = new IotToolbarRegularButtonConfig(openHistoryButtonConfig);

/// ///////////////////////
// *** MENU CONFIGS *** //
/// ///////////////////////

export class IotToolbarMenuButtonConfig implements Partial<IotToolbarButtonConfig> {
  icon = '';
  tooltip = '';
  displayButton = true;
  color = 'accent' as ThemePalette;
  type = IotToolbarButtonType.MENU_BUTTON;
  disabled = false;
  order = 1;
  menuOptions: Partial<IotToolbarMenuButtonOption>[] = [];

  constructor(customConfig: Partial<IotToolbarButtonConfig>) {
    Object.keys(customConfig).forEach((property: string) => (this[property] = customConfig[property] ?? this[property]));
  }
}

const menuButtonBaseConfig: Partial<IotToolbarMenuButtonConfig> = {
  displayButton: true,
  color: 'accent' as ThemePalette,
  disabled: false,
  type: IotToolbarButtonType.MENU_BUTTON,
  order: 1
};

const downloadTranslationFilesButtonConfig: Partial<IotToolbarMenuButtonConfig> = {
  ...menuButtonBaseConfig,
  icon: 'get_app'
};

const manageMasterViewsButtonConfig: Partial<IotToolbarMenuButtonConfig> = {
  ...menuButtonBaseConfig,
  icon: 'settings',
  menuOptions: [
    { order: 2, label: 'ADMIN.ORGANIZATIONS.MANAGE_GRID_SETTINGS', dispatchAction: { type: IotToolbarDispatchActionType.OPEN_GRID_ADMIN_SETTINGS } },
    { order: 1, label: 'MV_TOOLBAR.TOOLTIP.MANAGE_COLUMNS', dispatchAction: { type: IotToolbarDispatchActionType.OPEN_MASTER_VIEW_ADMIN_SETTINGS } }
  ]
};

const manageFavoriteViewsMenuButtonConfig: Partial<IotToolbarMenuButtonConfig> = {
  ...menuButtonBaseConfig,
  icon: 'filter_alt',
  tooltip: 'IOT_TOOLBAR.TOOLTIP.FAVORITE_VIEWS_MENU',
  displayButton: false,
  menuOptions: [
    { order: 1, label: 'FILTER_ENGINE.CREATE_FAVORITE_VIEW', dispatchAction: { type: IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW } },
    { order: 2, label: 'FILTER_ENGINE.EDIT_FAVORITE_VIEW', dispatchAction: { type: IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW } },
    { order: 3, label: 'FILTER_ENGINE.DELETE_FAVORITE_VIEW', dispatchAction: { type: IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW } }
  ]
};

const manageFavoriteViewsButtonConfig: Partial<IotToolbarRegularButtonConfig> = {
  ...regularButtonBaseConfig
};

export const DOWNLOAD_TRANSLATIONS_BUTTON_CONFIG = new IotToolbarMenuButtonConfig(downloadTranslationFilesButtonConfig);
export const MANAGE_MASTER_VIEWS_BUTTON_CONFIG = new IotToolbarMenuButtonConfig(manageMasterViewsButtonConfig);
export const FAVORITE_VIEWS_MENU_BUTTON_CONFIG = new IotToolbarMenuButtonConfig(manageFavoriteViewsMenuButtonConfig);
export const FAVORITE_VIEWS_BUTTONS_CONFIG = new IotToolbarRegularButtonConfig(manageFavoriteViewsButtonConfig);
