import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CustomEncoder } from '@iot-platform/core';
import { CommonApiListResponse, Filter, Pagination, PlatformRequest } from '@iot-platform/models/common';
import { groupBy } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseService {
  constructor(public http: HttpClient) {}

  get<T>(
    request: PlatformRequest,
    pagination: Pagination,
    sort: Sort,
    query: string = null,
    filters: Filter[] = [],
    filterSiteId?: string
  ): Observable<CommonApiListResponse<T>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request ? request.limit.toString(10) : pagination.limit.toString(10));
    params = params.set('page', request ? request.page.toString(10) : pagination.currentPage.toString(10));
    if (query) {
      params = params.set('query', query);
    }
    if (filters.length > 0) {
      const groupedFilter = groupBy(filters, 'criteriaKey');
      for (const key in groupedFilter) {
        if (Object.prototype.hasOwnProperty.call(groupedFilter, key)) {
          params = params.set(key, groupedFilter[key].map((item) => item.value).join(','));
        }
      }
    }
    if (sort && sort.active && sort.direction) {
      params = params.set('orderBy', `${sort.active}-${sort.direction}`);
    }
    if (filterSiteId) {
      params = params.set('siteId', filterSiteId);
    }

    return this.http.get<CommonApiListResponse<T>>(this.getEndpoint(), { params });
  }

  getEndpoint() {
    return '';
  }
}
