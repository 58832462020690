import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-timeline-content',
  templateUrl: './timeline-content.component.html',
  styleUrls: ['./timeline-content.component.scss']
})
export class TimelineContentComponent {
  @Input() visible = true;
}
