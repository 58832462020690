import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthorizationConcept, AuthorizationType } from '../authorization.types';
import { AuthService } from '../services/auth.service';
import { AuthorizationService } from '../services/authorization.service';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[iotPlatformApplyAuthorization]' })
export class ApplyAuthorizationDirective {
  condition: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthService,
    private authorizationService: AuthorizationService
  ) {}

  @Input('iotPlatformApplyAuthorization')
  set iotPlatformAuthApplyAuthorization(authorizations: Authorization[]) {
    authorizations.forEach((auth: { concept: AuthorizationConcept; type: AuthorizationType }) => {
      if (this.authorizationService.applyAuthorization(auth.concept, auth.type)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}

interface Authorization {
  concept: string;
  type: string;
}
