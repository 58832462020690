import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-timeline-list',
  templateUrl: './timeline-list.component.html',
  styleUrls: ['./timeline-list.component.scss']
})
export class TimelineListComponent {
  @Input() title!: string;
  @Input() icon!: string;
}
