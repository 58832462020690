import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  standalone: true,
  imports: [NgIf, NgFor, FlexLayoutModule, MatIconModule, MatButtonModule, MatMenuModule, UpperCasePipe, TranslateModule],
  selector: 'iot-platform-ui-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent extends BaseCardComponent<unknown> {}
