import { Injectable } from '@angular/core';
import { AbstractAuthFacade, BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthApiActions, AuthBusinessProfilesPageActions, AuthPageActions } from '../actions';
import * as fromAuth from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade extends AbstractAuthFacade {
  constructor(private readonly store: Store) {
    super();
    this.authPending$ = this.store.select(fromAuth.selectAuthPagePending);
    this.currentUser$ = this.store.select(fromAuth.selectCurrentUser);
    this.privileges$ = this.store.select(fromAuth.selectPrivileges);
    this.selectedBusinessProfile$ = this.store.select(fromAuth.selectSelectedBusinessProfileForAccount);
    this.businessProfiles$ = this.store.select(fromAuth.selectBusinessProfilesForAccount);

    this.listenForBusinessProfileChanges();
    this.initEligibleBusinessProfiles();
  }

  listenForBusinessProfileChanges(): void {
    this.isBusinessProfileChanged$ = combineLatest([this.selectedBusinessProfile$, this.store.select(fromAuth.selectSelectedBusinessProfileForAccount)]).pipe(
      map(([oldBp, newBp]) => oldBp && newBp && oldBp.id !== newBp.id)
    );
  }

  initEligibleBusinessProfiles(): void {
    this.eligibleBusinessProfiles$ = this.businessProfiles$.pipe(
      map((businessProfiles: BusinessProfile[]) => businessProfiles && businessProfiles.filter((bp) => bp.level === 2))
    );
  }

  loadPrivilegesSuccess(privileges: { [key: string]: string[] }): void {
    this.store.dispatch(AuthApiActions.loadPrivilegesSuccess({ privileges }));
  }

  selectBusinessProfile(selectedBusinessProfile: BusinessProfile, withRedirect: boolean): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile,
        withRedirect
      })
    );
  }

  signOut(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }
}
