import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'iot-platform-ui-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
  encapsulation: ViewEncapsulation.None
})
export class YearPickerComponent implements OnInit {
  @Input() label = 'DATE_RANGE.PLACEHOLDER_YEAR';
  @Input() initialYear: number = moment().year();
  @Input() max: moment.Moment = moment();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onYearChange: EventEmitter<string> = new EventEmitter<string>();

  yearControl!: FormControl;

  ngOnInit(): void {
    this.yearControl = new FormControl(moment().year(this.initialYear));
    this.onYearChange.emit(this.initialYear.toString());
  }

  onYearSelection(year: number, datepicker: any): void {
    datepicker.close();
    this.yearControl.setValue(moment().year(year));
    this.onYearChange.emit(this.yearControl.value?.year().toString());
  }
}
