<div data-cy='iot-platform-ui-timeline-list-item' class='timeline-list__item' [ngClass]='cssClassName' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
  <div fxFlex='30' fxLayout='row' fxLayoutAlign='start' fxLayoutGap='10px'>
    <iot-platform-ui-icon class='icon' [name]='icon'></iot-platform-ui-icon>
    <div fxLayout='row' fxLayout.lt-md='column' fxLayoutAlign='start center' fxLayoutAlign.lt-md='start' fxLayoutGap='10px'>
      <span data-cy='iot-platform-ui-timeline-list-item-timestamp' class='timeline-list__item_timestamp'>{{ timestamp | date: 'MMM.dd - yyyy, HH:mm:ss' }}</span>
      <span data-cy='iot-platform-ui-timeline-list-item-label' class='timeline-list__item_label'>{{ label | translate }}</span>
    </div>
  </div>

  <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px' fxFlex='70' class='separator'>
    <span data-cy='iot-platform-ui-timeline-list-item-description' class='timeline-list__item_description' *ngIf='description; else contentTmpl'>{{ description | translate }}</span>
    <ng-template data-cy='iot-platform-ui-timeline-list-item-content' #contentTmpl>
      <ng-content></ng-content>
    </ng-template>
  </div>
</div>
