import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { TimelineListItemComponent } from './timeline-list-item/timeline-list-item.component';
import { TimelineListComponent } from './timeline-list.component';

const COMPONENTS = [TimelineListComponent, TimelineListItemComponent];

@NgModule({
  imports: [CommonModule, TranslateModule, IconModule, FlexLayoutModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class TimelineListModule {}
