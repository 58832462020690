<div *ngIf='!(loggedIn$ | async) else selectBpTmpl' fxLayout='column' fxLayoutAlign='center stretch' fxLayoutGap='10px'>
  <span class='welcome'>{{ 'LOGIN.WELCOME_TO' | translate }}</span>
  <img class='logo' src='assets/images/logo-login.svg' alt='' />
  <button mat-raised-button (click)='ssoLogin()' class='sso-action'>{{ 'LOGIN.SSO_CONNECT' | translate }}</button>
</div>

<ng-template #selectBpTmpl>
  <div class='select-bp' fxLayout='column' fxLayoutAlign='center center'>
    <iot-platform-login-select-profile
      [businessProfiles$]='bpList$'
      [loading]='this.pending$ | async'
      (selectProfile)='onSelectProfile($event)'
    ></iot-platform-login-select-profile>

    <button *ngIf='!(bpList$ | async)?.length'
            class='select-bp__back-button'
            type='button' mat-button color='primary' (click)='backToLogin()'>
      {{ 'LOGIN.BACK_TO_LOGIN' | translate }}
    </button>
  </div>
</ng-template>
