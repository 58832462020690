import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionButtonComponent } from './subscription-button.component';

@NgModule({
  declarations: [SubscriptionButtonComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [SubscriptionButtonComponent]
})
export class SubscriptionButtonModule {}
