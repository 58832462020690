import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ProgressBarButtonComponent } from './progress-bar-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, TranslateModule, MatTooltipModule],
  exports: [ProgressBarButtonComponent],
  declarations: [ProgressBarButtonComponent]
})
export class ProgressBarButtonModule {}
