import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-download-card',
  templateUrl: './download-card.component.html',
  styleUrls: ['./download-card.component.scss']
})
export class DownloadCardComponent {
  @Input() loading = false;
  @Input() cardText = '';
  @Input() buttonText = '';
  @Input() isVisible = false;

  @Output() download: EventEmitter<void> = new EventEmitter<void>();
}
