// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: '',
  production: false,
  sso: {
    domain: 'https://qlixbi-dev-20201118.auth.eu-west-1.amazoncognito.com',
    clientId: '6bkmnsdgf9idce9vhnamrpmknc',
    idpIdentifier: 'multipass',
    responseType: 'code',
    redirectUri: 'login/callback'
  },
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Zk0qvgbay',
    clientId: '6bkmnsdgf9idce9vhnamrpmknc',
    multipassClientId: '30vd381in50nhigrfuppclabat',
    ssoUrl:
      // tslint:disable-next-line: max-line-length
      'https://qlixbi-dev-20201118.auth.eu-west-1.amazoncognito.com/authorize?idp_identifier=multipass&response_type=code&client_id=[clientId]&redirect_uri=[origin]/login/callback  '
  },
  api: {
    url: '/api/v1',
    // url: 'https://adminportal.dev.pg.iot.airliquide.com/api/v1',
    key: '',
    endpoints: {
      account: '/account',
      allocations: '/allocations',
      assets: '/assets',
      businessProfiles: '/business-profiles',
      devices: '/devices',
      entities: '/entities',
      events: '/events',
      orders: '/orders',
      products: '/products',
      sites: '/sites',
      traces: '/traces',
      timeline: '/timeline',
      timelineEvents: '/timeline-events',
      maintenanceOperations: '/maintenance-operations',
      endOfMaintenance: '/end-of-maintenance',
      senToMaintenance: '/send-to-maintenance',
      rotarex: '/rotarex',
      servitrax: '/servitrax',
      roles: '/roles',
      tags: '/tag-categories',
      users: '/users',
      usersLightWeight: '/directory/users'
    },
    urlManageWeb: 'https://pgmanagement.dev.pg.iot.airliquide.com',
    urlFabAssistWeb: 'https://fabassist.uat.pg.iot.airliquide.com'
  },
  storage: {
    appPrefix: 'qlixbi'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
