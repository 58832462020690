import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragAndDropWrappedLayoutComponent } from './drag-and-drop-wrapped-layout.component';

@NgModule({
  declarations: [DragAndDropWrappedLayoutComponent],
  imports: [CommonModule, DragDropModule],
  exports: [DragDropModule]
})
export class DragAndDropModule {
}
