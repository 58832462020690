import { environment as commonConfig } from './environment.common';

export const environment = {
  ...commonConfig,
  env: 'lab',
  production: false,
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_Zk0qvgbay',
    clientId: '6bkmnsdgf9idce9vhnamrpmknc',
    multipassClientId: '30vd381in50nhigrfuppclabat',
    ssoUrl:
      // tslint:disable-next-line: max-line-length
      'https://qlixbi-dev-20201118.auth.eu-west-1.amazoncognito.com/authorize?idp_identifier=multipass&response_type=code&client_id=[clientId]&redirect_uri=[origin]/login/callback  '
  },
  api: {
    ...commonConfig.api,
    key: '',
    urlManageWeb: 'https://pgmanagement.dev.pg.iot.airliquide.com',
    urlFabAssistWeb: 'https://fabassist.uat.pg.iot.airliquide.com'
  }
};
