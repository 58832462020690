import { AsyncPipe, NgFor } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TagCategory } from '@iot-platform/models/common';

import { BehaviorSubject, Observable } from 'rxjs';

import { ColorPickerService } from '../color-picker/color-picker.service';
import { AbstractTagEditorService } from './abstract-tag-editor.service';
import { TagEditorFormComponent } from './tag-editor-form/tag-editor-form.component';

@Component({
  standalone: true,
  imports: [AsyncPipe, NgFor, FlexLayoutModule, TagEditorFormComponent],
  selector: 'iot-platform-ui-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagEditorComponent implements OnInit, AfterContentInit {
  @Input() organizationId: string;
  @Input() concept: string;

  @Output() changeValue: EventEmitter<boolean> = new EventEmitter();

  areCategoriesSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  newCategory: TagCategory;
  colors$: Observable<string[]> = this.colorPickerService.getColorList();

  categories$: Observable<TagCategory[]> = this.tagEditorService.getCategories$();
  initialCats: TagCategory[];

  constructor(
    private readonly colorPickerService: ColorPickerService,
    private readonly tagEditorService: AbstractTagEditorService
  ) {}

  ngOnInit() {
    this.categories$.subscribe((cat) => {
      this.initialCats = cat;
      this.areCategoriesSaved$.next(true);
    });

    this.newCategory = { entityId: this.organizationId, concept: this.concept.toUpperCase(), labels: [], mandatory: false, color: '', name: '' };

    this.areCategoriesSaved$.subscribe((value) => this.changeValue.emit(value));
  }

  ngAfterContentInit(): void {
    if (this.organizationId && this.concept) {
      this.tagEditorService.getListTagsByOrganizationForEdition(this.organizationId, false, this.concept);
    }
  }

  onChangeValue(): void {
    this.areCategoriesSaved$.next(false);
  }

  onAddTagCategory(category: TagCategory) {
    if (category) {
      this.tagEditorService.addTagToOrganization(category);
    }
  }

  onUpdateTagCategory(category: TagCategory) {
    this.tagEditorService.updateTagInOrganization(category);
  }

  onRemove(category: TagCategory) {
    this.tagEditorService.removeTagFromOrganization(category);
  }
}
