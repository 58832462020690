<div class="detail-panel-header" fxLayout="row" fxLayoutAlign="start center">
  <h2 class="detail-panel-title">{{ title }}</h2>
  <hr class="detail-panel-line" />
  <div class="detail-panel-button" *ngIf="hasButton">
    {{ 'DETAIL_PANEL.BUTTON' | translate }}
    <div class="app-round-button-mv">
      <button mat-icon-button (click)="onClick()"><mat-icon>add_comment</mat-icon></button>
    </div>
  </div>
</div>
<ng-content></ng-content>
