import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CommonApiListResponse, Environment, Filter, Pagination, PlatformRequest } from '@iot-platform/models/common';
import { Order, PagedResult } from '@iot-platform/models/qlixbi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class OrdersService extends BaseService {
  private ordersEndpoint = `${this.environment.api.url}${this.environment.api.endpoints.orders}`;

  constructor(public http: HttpClient, @Inject('environment') private readonly environment: Environment) {
    super(http);
  }

  getEndpoint() {
    return this.ordersEndpoint;
  }

  getAll(
    request: PlatformRequest,
    pagination: Pagination,
    sort: Sort,
    query: string = null,
    filters: Filter[] = [],
    filterSiteId?: string
  ): Observable<PagedResult<Order>> {
    return super.get<Order>(request, pagination, sort, query, filters, filterSiteId).pipe(
      map((data: CommonApiListResponse<Order>) => ({
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total,
          initialSort: sort
        }))
    );
  }

  getById(siteId: string, orderId: string): Observable<Order> {
    return this.http.get<Order>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/orders/${orderId}`);
  }
}
