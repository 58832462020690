import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: []
})
export class TagsListComponent {
  @Input() title = 'MANAGE_TAGS_FORM.TAGS';
  @Input() tooltip = 'MANAGE_TAGS_FORM.MANAGE_TAGS';
  @Input() loading = false;
  @Input() tags: TagCategory[] = [];
  @Input() canCreateOrUpdateTag = true;

  @Output() manageTags: EventEmitter<void> = new EventEmitter<void>();
}
