import { CdkDrag, CdkDragEnter, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';

/** *
 Drag and drop workaround on wrapped list using Angular >= v 12
 // https://stackblitz.com/edit/angular-ivy-hhqtt3?file=src%2Fapp%2Fapp.component.ts
 */
@Component({
  template: ''
})
export class DragAndDropWrappedLayoutComponent implements AfterViewInit {
  @ViewChild(CdkDropListGroup) listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder!: CdkDropList;
  target!: CdkDropList | null;
  targetIndex!: number;
  source!: CdkDropList | null;
  sourceIndex!: number;
  dropListItems: any[] = [];
  onCdkDropListDropped: EventEmitter<void> = new EventEmitter<void>();

  ngAfterViewInit(): void {
    const phElement: HTMLElement = this.placeholder.element.nativeElement;
    if (phElement) {
      phElement.style.display = 'none';
      phElement.parentElement?.removeChild(phElement);
    }
  }

  cdkDropListDropped() {
    if (!this.target) {
      return;
    }

    const phElement: HTMLElement = this.placeholder.element.nativeElement;
    const parent: HTMLElement | null = phElement.parentElement;

    phElement.style.display = 'none';

    parent?.removeChild(phElement);
    parent?.appendChild(phElement);

    if (this.source?.element?.nativeElement && parent) {
      parent?.insertBefore(this.source.element.nativeElement, parent?.children[this.sourceIndex]);
    }

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.dropListItems, this.sourceIndex, this.targetIndex);
      this.onCdkDropListDropped.emit();
    }
  }

  cdkDropListEntered(e: CdkDragEnter) {
    const drag: CdkDrag<any> = e.item;
    const drop: CdkDropList<any> = e.container;

    if (drop === this.placeholder) {
      return true;
    }

    const phElement: HTMLElement = this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = drag.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = drop.element.nativeElement;

    const dragIndex: number = this.indexOf(dropElement?.parentElement?.children, this.source ? phElement : sourceElement);
    const dropIndex: number = this.indexOf(dropElement?.parentElement?.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      sourceElement?.parentElement?.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement?.parentElement?.insertBefore(phElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement);
    return false;
  }

  private indexOf(collection: HTMLCollection | undefined, node: HTMLElement): number {
    return Array.prototype.indexOf.call(collection ?? [], node);
  }
}
