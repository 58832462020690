import { Injectable } from '@angular/core';
import { NotificationService as BaseNotificationService } from '@iot-platform/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly notificationService: BaseNotificationService, private readonly translateService: TranslateService) {}

  displaySuccess(value: string): void {
    this.notificationService.displaySuccess(value);
  }

  displayError(error): void {
    this.notificationService.displayError(this.formatError(error));
  }

  displayLoader(value: boolean): void {
    this.notificationService.displayLoader(value);
  }

  hideLoader(): void {
    this.notificationService.hideLoader();
  }

  showLoader(): void {
    this.notificationService.showLoader();
  }

  private formatError(error): string {
    // This is the default behaviour used to format qlixbi errors
    if (error.error instanceof ErrorEvent) {
      return `Error: ${error.error.message}`;
    }
    return `Error Code: ${error.status},  Message: ${this.translateService.instant('COMMON.HTTP_ERRORS.' + error.status)}`;
  }
}
