import { BusinessProfile, UserAccount } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

export abstract class AbstractAuthFacade {
  authPending$!: Observable<boolean>;
  currentUser$!: Observable<UserAccount>;
  privileges$!: Observable<{ [key: string]: string[] }>;
  selectedBusinessProfile$!: Observable<BusinessProfile>;
  businessProfiles$!: Observable<BusinessProfile[]>;
  isBusinessProfileChanged$!: Observable<boolean>;
  eligibleBusinessProfiles$!: Observable<BusinessProfile[]>;

  abstract listenForBusinessProfileChanges(): void;

  abstract initEligibleBusinessProfiles(): void;

  abstract loadPrivilegesSuccess(privileges: { [key: string]: string[] }): void;

  abstract selectBusinessProfile(selectedBusinessProfile: BusinessProfile, withRedirect: boolean): void;

  abstract signOut(): void;
}
