import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'iot-platform-ui-spinner-background',
  templateUrl: './spinner-background.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'spinner-container',
    '[style.width.px]': 'diameter',
    '[style.height.px]': 'diameter',
    '[style.line-height.px]': 'diameter'
  },
  styleUrls: ['./spinner-background.component.scss']
})
export class SpinnerBackgroundComponent implements OnChanges {
  progress$: BehaviorSubject<number> = new BehaviorSubject<number>(50);
  mode = 'determinate';

  @Input() color: ThemePalette = 'accent';
  @Input() diameter!: number;
  @Input() strokeWidth!: number;
  @Input() value!: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('value')) {
      this.progress$.next(!changes.value.currentValue || Number.isNaN(changes.value.currentValue) ? 0 : changes.value.currentValue);
    }
  }
}
