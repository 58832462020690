import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'iot-platform-users-user-profile-preferences',
  templateUrl: './user-profile-preferences.component.html',
  styleUrls: ['./user-profile-preferences.component.scss']
})
export class UserProfilePreferencesComponent {
  @Input() currentUser: UserAccount;
  @Input() preferences: UserPreferences;
  @Input() withNotifications = true;

  @Output() changeUserPreferences: EventEmitter<UserAccount> = new EventEmitter<UserAccount>();

  today = moment();
  defaultAppDateFormat = 'yyyy-MM-DD HH:mm:ss';
  defaultAppNumberFormat = 'fr';
  defaultAppNumberOfDecimal = 3;
  defaultFilterEngineState = false;

  constructor(private readonly translateService: TranslateService) {}

  getNotifications() {
    return Object.entries(this.currentUser.notifications)
      .filter((notification) => notification[1])
      .reduce((translationsInArray, notification) => {
        translationsInArray.push(this.translateService.instant('USERS.PROFILE_PREFERENCES.' + notification[0].toLocaleUpperCase()));
        return translationsInArray;
      }, [])
      .join(', ');
  }
}
