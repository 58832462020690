import { CommonGenericModel, LastValue } from '@iot-platform/models/common';

export interface ExportSpreadsheet extends CommonGenericModel {
  assetVariables: { id: string; name: string; lastValue: LastValue; asset: { id: string; name: string } }[];
  contactIds: string[];
  description?: string;
  expandedContacts?: ExportSpreadsheetExpandedContact[];
  frequency: EXPORT_SPREADSHEET_FREQUENCY;
  // jobs?: {
  //   createdAt: string | null;
  //   updatedAt: string | null;
  //   exportId: string;
  //   id: string;
  //   s3DeletedAt: string | null;
  //   s3Key: string;
  //   presignedUrl: string;
  // }[];
  lastRun?: string | null;
  margin: number;
  model: EXPORT_SPREADSHEET_MODEL;
  nextRun?: string;
  status: 'active' | 'inactive';
}

export enum EXPORT_SPREADSHEET_FREQUENCY {
  EVERY_4_HOURS = 'EVERY_4_HOURS',
  EVERY_12_HOURS = 'EVERY_12_HOURS',
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_MONTH = 'EVERY_MONTH'
}

export enum EXPORT_SPREADSHEET_MODEL {
  ONE_ASSET_PER_TAB = 'ONE_ASSET_PER_TAB',
  SINGLE_TAB = 'SINGLE_TAB'
}

export enum EXPORT_SPREADSHEET_STATUS {
  ACTIVE = 'active',
  DISABLED = 'DISABLED'
}

export interface ExportSpreadsheetExpandedContact {
  id: string;
  firstname: string;
  lastname: string;
  notificationSupports: { email: boolean; sms: boolean };
  incorrectEmailSetting: boolean;
  incorrectSmsSetting: boolean;
}
