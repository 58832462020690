import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LoginSelectProfileComponent } from './login-select-profile.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, FlexLayoutModule, TranslateModule, MatButtonModule, MatIconModule, MatTooltipModule],
  declarations: [LoginSelectProfileComponent],
  exports: [LoginSelectProfileComponent]
})
export class LoginSelectProfileModule {}
