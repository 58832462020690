import { Component, Input } from '@angular/core';
import { TimeseriesWidgetOptions, WidgetInstance } from '@iot-platform/models/widgets';

@Component({
  selector: 'iot-platform-ui-timeseries-widget',
  templateUrl: './timeseries-widget.component.html',
  styleUrls: ['./timeseries-widget.component.scss']
})
export class TimeseriesWidgetComponent {
  @Input() widget!: WidgetInstance;

  get options(): TimeseriesWidgetOptions | null {
    return this.widget ? this.widget.options : null;
  }
}
