import { ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { WidgetInstance, WidgetsMapper, WidgetTypes } from '@iot-platform/models/widgets';
import { TimeseriesWidgetComponent } from '../components/timeseries-widget/timeseries-widget.component';

const WIDGET_COMPONENT_MAPPER: WidgetsMapper = {
  [WidgetTypes.TIME_SERIES]: TimeseriesWidgetComponent
};

@Directive({
  selector: '[iotPlatformUiWidgetLoader]'
})
export class WidgetLoaderDirective implements OnChanges, OnInit {
  @Input() public widget!: WidgetInstance;
  public component!: ComponentRef<any>;

  constructor(private readonly container: ViewContainerRef) {}

  public ngOnChanges(): void {
    if (this.widget && this.component) {
      this.component.instance.widget = this.widget;
    }
  }

  public ngOnInit(): void {
    if (this.widget && this.widget.type) {
      this.component = this.container.createComponent(WIDGET_COMPONENT_MAPPER[this.widget.type]);
      this.component.instance.widget = this.widget;
    }
  }
}
