import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@iot-platform/notification';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import * as authPageActions from '../../state/actions/auth-page.actions';

@Component({
  selector: 'iot-platform-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginCallbackComponent implements OnInit {
  constructor(
    protected notificationService: NotificationService,
    protected authService: AuthService,
    protected translate: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store
  ) {}

  ngOnInit() {
    this.notificationService.showLoader();
    this.route.queryParams
      .pipe(
        switchMap((queryParams) =>
          this.authService.getSsoTokensFromCode(queryParams?.code).pipe(
            catchError(() => {
              setTimeout(() => {
                this.notificationService.displayError(this.translate.instant('AUTHENTICATION_FAILED'));
              });
              return of(null);
            })
          )
        )
      )
      .subscribe((tokens) => {
        if (tokens) {
          const { idToken, accessToken, refreshToken, tokenType, expiresIn } = tokens;
          this.authService.storeSsoTokens({ idToken, accessToken, tokenType, refreshToken, expiresIn: String(expiresIn) });
          this.store.dispatch(authPageActions.validateSsoTokens({ idToken, accessToken, refreshToken, expiresIn }));
        } else {
          this.router.navigate(['/']);
        }
        this.notificationService.hideLoader();
      });
  }
}
