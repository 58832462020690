import { HttpParams } from '@angular/common/http';
import { CustomEncoder } from '@iot-platform/core';
import {
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  Filter,
  Pagination
} from '@iot-platform/models/common';
import { isNil } from 'lodash';

export class ApiHelpers {
  static getHttpParams(request: CommonApiRequest): HttpParams {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (request) {
      if (!isNil(request.limit)) {
        params = params.set('limit', request.limit.toString(10));
      }
      if (!isNil(request.page)) {
        params = params.set('page', request.page ? request.page.toString(10) : '0');
      }
      if (request.filters) {
        request.filters.forEach((filter: Filter) => {
          params = params.append(filter.criteriaKey, filter.value);
        });
      }
    }
    return params;
  }

  static getHttpResponse<T>(
    data: CommonApiListResponse<T> | T[],
    options?: { page: number; limit: number; total?: number }
  ): CommonApiResponse<T, Pagination> {
    let pagination: Pagination;
    if (data instanceof Array) {
      const limit = options ? options.limit : 10;
      let total = data?.length;
      if (options && options.total && total > 0) {
        total = options.total;
      }
      pagination = {
        currentPage: options ? options.page : 0,
        limit,
        hasMore: true,
        maxPage: total / limit,
        total
      };
    } else {
      pagination = {
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      };
    }
    return {
      data: data instanceof Array ? data : data.content,
      pagination
    };
  }
}
