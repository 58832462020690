export class ArrayUtils {
  static pluck = (key) => (array) => Array.from(new Set(array.map((obj) => obj[key])));

  /**
   * Combine duplicates elements
   *
   * @usageNotes
   *
   * ```
   * const array = [
   *    { key:"manage", value:"0"},
   *    { key:"manage", value:"1"},
   *    { key:"manage", value:"2"},
   *    { key:"fab", value:"0"},
   *    { key:"fab", value:"1"},
   *    { key:"limit", value:"10"}
   *  ];
   *
   * let result = combineDuplicates('key', array)
   * console.log(result);   // [{ key: 'manage', values: '0,1,2' }, { key: 'fab', values: '0,1' }, { key: 'limit', values: '10' } ]
   *
   * result = combineDuplicates('key', array, ['fab'])
   * console.log(result);   // [{ key: 'manage', values: '0,1,2' }, { key: 'fab', values: '1' }, { key: 'limit', values: '10' } ]
   * ```
   *
   * @param criteriaKey the criteria key.
   * @param array selected filters.
   * @param exclusions Optional parameter. It represent excluded properties
   *
   * Excluded properties will get the last selected value in the filter
   */
  static combineDuplicates = (criteriaKey: string, array: { value: string; key: string }[], exclusions?: string[]) => ArrayUtils.pluck(criteriaKey)(array).map((key: string) => {
      const values = array.filter((e) => e[criteriaKey] === key).map((e) => e.value);
      return {
        key,
        values: exclusions && exclusions.includes(key) ? values.pop() : values.join()
      };
    });
}
