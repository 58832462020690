import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderModule } from '../card-loader/card-loader.module';
import { ChipModule } from '../chip/chip.module';
import { DetailPopupCardsModule } from '../detail-popup-cards/detail-popup-cards.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { SiteDetailAssetsComponent } from './site-detail-assets/site-detail-assets.component';
import { SiteDetailBasicInfoComponent } from './site-detail-basic-info.component.ts/site-detail-basic-info.component';
import { SiteDetailContactsComponent } from './site-detail-contacts/site-detail-contacts.component';
import { SiteDetailDevicesComponent } from './site-detail-devices/site-detail-devices.component';
import { SiteDetailTagsComponent } from './site-detail-tags/site-detail-tags.component';
import { SiteDetailsFullInfoComponent } from './site-details-full-info/site-details-full-info.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    IotPlatformPipesModule,
    DetailPopupModule,
    DetailPopupCardsModule,
    CardLoaderModule,
    ChipModule,
    MatChipsModule
  ],
  providers: [],
  declarations: [
    SiteDetailBasicInfoComponent,
    SiteDetailContactsComponent,
    SiteDetailAssetsComponent,
    SiteDetailDevicesComponent,
    SiteDetailTagsComponent,
    SiteDetailsFullInfoComponent
  ],
  exports: [
    SiteDetailBasicInfoComponent,
    SiteDetailContactsComponent,
    SiteDetailAssetsComponent,
    SiteDetailDevicesComponent,
    SiteDetailTagsComponent,
    SiteDetailsFullInfoComponent
  ]
})
export class SiteDetailsModule {}
