import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-timeline-header',
  templateUrl: './timeline-header.component.html',
  styleUrls: ['./timeline-header.component.scss']
})
export class TimelineHeaderComponent {
  @Input() label!: string;
}
