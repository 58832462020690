import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserDateFormats } from '@iot-platform/models/common';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(
    @Inject('environment') private environment,
    private http: HttpClient,
    private overlayContainer: OverlayContainer
  ) {}

  public loadProfile(): Observable<any> {
    return this.http.get(`${this.environment.api.url}/account`);
  }

  public saveUserProfile(user): Observable<any> {
    return this.http.put(`${this.environment.api.url}/account`, user);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setThemeOverlay(theme: string): void {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));

    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }

    // overlayContainerClasses.add(theme);
    overlayContainerClasses.add('default-theme');
  }

  public getAppDateFormats(): Observable<UserDateFormats[]> {
    return this.http.get<UserDateFormats[]>(`assets/data/app-date-formats.json`);
  }
}
