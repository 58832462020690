import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardAction, CardEventType } from '@iot-platform/models/common';

@Component({
  template: ''
})
export abstract class BaseCardComponent<T> {
  @Input() element!: T;
  @Input() icon!: string;
  @Input() image!: string;
  @Input() withMenu = false;
  @Input() cssClasses!: string;
  @Input() actions: CardAction[] = [];

  @Output() dispatchEvent: EventEmitter<any> = new EventEmitter();

  onDispatchEvent(event: any): void {
    this.dispatchEvent.emit(event);
  }

  onElementClicked(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ELEMENT_CLICKED,
      data: this.element
    });
  }
}
