import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Contact } from '@iot-platform/models/common';
import { ExportSpreadsheet } from '@iot-platform/models/i4b';
import { InfoDisplayModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [TranslateModule, MatIconModule, MatTooltipModule, FlexLayoutModule, MatMenuModule, MatButtonModule, InfoDisplayModule, NgIf, NgFor],
  selector: 'iot-platform-ui-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContactCardComponent {
  @Input() contact!: Contact;
  @Input() canUpdate?: boolean;
  @Input() canDelete?: boolean;
  @Input() minimalDisplay = true; // display only fullname, job, phone and email

  @Output() toEdit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() configNotif: EventEmitter<any> = new EventEmitter();
  @Output() configExport: EventEmitter<any> = new EventEmitter();
  @Output() openNotificationDetail: EventEmitter<any> = new EventEmitter();
  @Output() openExportSpreadsheetDetail: EventEmitter<any> = new EventEmitter();

  spreadsheetExport$ = new BehaviorSubject<ExportSpreadsheet | NonNullable<unknown>>({});
  loading$!: Observable<boolean>;
  loaded$!: Observable<boolean>;

  get initials(): string {
    return this.contact?.firstname?.[0]?.toUpperCase() + this.contact?.lastname?.[0]?.toUpperCase();
  }

  getFilterByKey(key: string): string[] {
    return this.contact.notificationFilter?.filter((item) => item.criteriaKey === key).map((i) => i.value) ?? [];
  }

  edit() {
    this.toEdit.emit();
  }

  configureNotif() {
    this.configNotif.emit();
  }

  configureExport() {
    this.configExport.emit(this.spreadsheetExport$.value);
  }

  onDelete() {
    this.delete.emit();
  }

  onNotificationsClicked() {
    if (this.contact.associatedNotifications?.length) {
      this.openNotificationDetail.emit();
    }
  }

  onExportSpreadsheetsClicked() {
    if (this.contact.totalExports) {
      this.openExportSpreadsheetDetail.emit();
    }
  }
}
