<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'EXPORTS.TITLE' | translate }}</h1>
    </mat-toolbar>
  </mat-card-title>

  <mat-progress-bar class="exports-loader" mode="buffer" color="accent" [value]="loaderValue" *ngIf="displayLoader"> </mat-progress-bar>

  <form class="full-width" [formGroup]="exportsForm">
    <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">
      <mat-form-field fxFlex color="accent" floatLabel="never">
        <span matPrefix>{{ time }}-</span>
        <input matInput formControlName="name" required pattern="\S.*" [placeholder]="'EXPORTS.NAME' | translate" />
        <span matSuffix>.csv</span>
        <mat-error *ngIf="name.invalid && name.errors.pattern">{{ 'EXPORTS.ERRORS.NAME' | translate }}</mat-error>
      </mat-form-field>
      <p class="dialog-form-tips">{{ 'EXPORTS.TIPS' | translate }}</p>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button type="button" class="button-regular" (click)="close()">{{ 'EXPORTS.CANCEL' | translate }}</button>
      <button
        mat-raised-button
        type="submit"
        class="button-regular"
        color="accent"
        [disabled]="(!exportsForm.valid && !isFileDownloaded) || isFileDownloading"
        (click)="action()"
      >
        {{ action$ | async }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
