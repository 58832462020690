import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FavoriteFilter, FavoriteFiltersByConcept, UserPreferences } from '@iot-platform/models/common';
import { SortUtil } from '@iot-platform/iot-platform-utils';

@Component({
  selector: 'iot-platform-ui-manage-favorite-filters-popup',
  templateUrl: './manage-favorite-filters-popup.component.html',
  styleUrls: ['./manage-favorite-filters-popup.component.scss']
})
export class ManageFavoriteFiltersPopupComponent implements OnInit {
  manageFavoriteFiltersForm!: UntypedFormGroup;
  favoriteFiltersMaximum = 6;
  favoriteFiltersMaximumPossible!: number;
  favoriteFiltersTotal = 0;
  favoriteFiltersForOrdering: FavoriteFilter[] = [];

  constructor(
    private dialogRef: MatDialogRef<ManageFavoriteFiltersPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { filterCriteria: any[]; masterView: string; userPreferences: UserPreferences }
  ) {}

  get favoriteFiltersControl(): AbstractControl {
    return this.manageFavoriteFiltersForm.get('favoriteFilters') as AbstractControl;
  }

  ngOnInit(): void {
    this.manageFavoriteFiltersForm = new UntypedFormGroup({
      favoriteFilters: new UntypedFormControl({}, [Validators.required, this.minFavoriteFilterValidator()])
    });

    this.initForm();
    this.setFavoriteFiltersMaximumPossible();
    this.setFavoriteFiltersCount();
  }

  initForm(): void {
    if (this.data.userPreferences.favoriteFilters && this.data.userPreferences.favoriteFilters[this.data.masterView]) {
      this.favoriteFiltersControl.setValue(this.removeDeprecatedFilters(this.data.userPreferences.favoriteFilters[this.data.masterView]));
      this.setFavoriteFiltersForOrdering();
      this.setFavoriteFiltersNewOrders();
    } else {
      this.favoriteFiltersControl.setValue(this.getAppDefaultFavoriteFilters());
      this.setFavoriteFiltersForOrdering();
      this.favoriteFiltersForOrdering.sort(SortUtil.sortByName);
      this.favoriteFiltersForOrdering = this.favoriteFiltersForOrdering.map((filter: FavoriteFilter, index: number) => ({ ...filter, order: index }));
      this.setFavoriteFiltersNewOrders();
    }
  }

  minFavoriteFilterValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const keys = Object.keys(value);

      return !keys.find((key) => !!value[key]?.length) ? { favoriteFiltersEmpty: true } : null;
    };
  }

  removeDeprecatedFilters(favoriteFilters: FavoriteFiltersByConcept): FavoriteFiltersByConcept {
    const conceptKeys = Object.keys(favoriteFilters);
    return conceptKeys.reduce((cleanFilters: FavoriteFiltersByConcept, key: string) => {
      const filterCriteriaByConcept = this.data.filterCriteria.find((criteria) => criteria.key === key);
      if (filterCriteriaByConcept) {
        favoriteFilters[key].forEach((ff) => {
          const matchingFilter = filterCriteriaByConcept.options.find((option) => option.key === ff.name);

          if (matchingFilter) {
            if (cleanFilters[key]) {
              cleanFilters[key].push(ff);
            } else {
              cleanFilters[key] = [ff];
            }
          }
        });
      }
      return cleanFilters;
    }, {});
  }

  getAppDefaultFavoriteFilters(): FavoriteFiltersByConcept {
    return this.data.filterCriteria.reduce((acc, filterCriteria) => {
      const defaultFavoriteFiltersByCriteria = filterCriteria.options
        .filter((filter: any) => !!filter.defaultFavorite)
        .map((filter: any) => ({ name: filter.key, order: undefined }));

      if (defaultFavoriteFiltersByCriteria.length) {
        acc = { ...acc, [filterCriteria.key]: defaultFavoriteFiltersByCriteria };
      }
      return acc;
    }, {});
  }

  setFavoriteFiltersMaximumPossible(): void {
    const filtersTotal = this.data.filterCriteria.reduce((acc: number, filter) => {
      acc += filter.options.length;
      return acc;
    }, 0);
    this.favoriteFiltersMaximumPossible = filtersTotal < this.favoriteFiltersMaximum ? filtersTotal : this.favoriteFiltersMaximum;
  }

  isFavoriteFilter(filter: any, parentConcept: string): boolean {
    if (this.favoriteFiltersControl.value) {
      return !!this.favoriteFiltersControl.value[parentConcept]?.find((f: FavoriteFilter) => f.name === filter.key) ?? false;
    } else {
      return false;
    }
  }

  setFavoriteFiltersCount(): void {
    if (this.favoriteFiltersControl.value) {
      const keys = Object.keys(this.favoriteFiltersControl.value);

      this.favoriteFiltersTotal = keys.reduce((acc, key) => {
        acc += this.favoriteFiltersControl.value[key].length;
        return acc;
      }, 0);
    }
  }

  onFilterSelectionChange(event: MatCheckboxChange, parentConcept: string): void {
    this.manageFavoriteFiltersForm.markAsDirty();

    const filterKey = event.source.value;
    const favoriteFilters: FavoriteFiltersByConcept = { ...this.favoriteFiltersControl.value };

    if (event.checked) {
      favoriteFilters[parentConcept] = favoriteFilters[parentConcept]?.length
        ? [...favoriteFilters[parentConcept], { name: filterKey, order: this.favoriteFiltersForOrdering.length }]
        : [{ name: filterKey, order: this.favoriteFiltersForOrdering.length }];
    } else {
      const favoriteFilterIndex = favoriteFilters[parentConcept].findIndex((filter: { name: string; order: number }) => filter.name === filterKey);
      favoriteFilters[parentConcept].splice(favoriteFilterIndex, 1);
    }

    this.favoriteFiltersControl.setValue(this.cleanFavoriteFilters(favoriteFilters));
    this.setFavoriteFiltersCount();
    this.setFavoriteFiltersForOrdering();
  }

  cleanFavoriteFilters(favoriteFilters: FavoriteFiltersByConcept): FavoriteFiltersByConcept {
    const keys = Object.keys(favoriteFilters);
    const cleanedFavoriteFilters: FavoriteFiltersByConcept = {};

    keys.forEach((key) => {
      if (favoriteFilters[key].length) {
        cleanedFavoriteFilters[key] = [...favoriteFilters[key]];
      }
    });
    return cleanedFavoriteFilters;
  }

  resetToAppDefault(): void {
    this.favoriteFiltersControl.setValue(this.getAppDefaultFavoriteFilters());
    this.setFavoriteFiltersForOrdering();
    this.favoriteFiltersForOrdering.sort(SortUtil.sortByName);
    this.favoriteFiltersForOrdering = this.favoriteFiltersForOrdering.map((filter: FavoriteFilter, index: number) => ({ ...filter, order: index }));
    this.setFavoriteFiltersNewOrders();
    this.setFavoriteFiltersCount();
    this.manageFavoriteFiltersForm.markAsDirty();
  }

  setFavoriteFiltersForOrdering(): void {
    this.favoriteFiltersForOrdering = Object.values(this.favoriteFiltersControl.value as FavoriteFiltersByConcept)
      .flat()
      .sort(SortUtil.sortByOrder);
  }

  drop(event: CdkDragDrop<FavoriteFilter[]>) {
    moveItemInArray(this.favoriteFiltersForOrdering, event.previousIndex, event.currentIndex);
    this.favoriteFiltersForOrdering = this.favoriteFiltersForOrdering.map((filter: FavoriteFilter, index: number) => ({ ...filter, order: index }));
    this.setFavoriteFiltersNewOrders();
    this.manageFavoriteFiltersForm.markAsDirty();
  }

  setFavoriteFiltersNewOrders(): void {
    const newFavoriteFiltersByConcept: FavoriteFiltersByConcept = { ...this.favoriteFiltersControl.value };

    Object.keys(newFavoriteFiltersByConcept).forEach((filterConcept: string) => {
      newFavoriteFiltersByConcept[filterConcept] = newFavoriteFiltersByConcept[filterConcept].map((filterToUpdate) => {
        const matchingFilter = this.favoriteFiltersForOrdering.find((filterWithNewOrder) => filterWithNewOrder.name === filterToUpdate.name);

        if (matchingFilter) {
          filterToUpdate.order = matchingFilter.order;
        }
        return filterToUpdate;
      });
    });

    this.favoriteFiltersControl.setValue(newFavoriteFiltersByConcept);
  }

  save(): void {
    this.dialogRef.close(this.favoriteFiltersControl.value);
  }

  close(): void {
    this.dialogRef.close();
  }
}
