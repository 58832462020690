import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Filter, FilterDefaultOptions } from '@iot-platform/models/common';

import { FilterComponent } from '../filter.component';

@Component({
  selector: 'iot-platform-ui-date-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit, FilterComponent {
  inputForm: UntypedFormGroup;

  @Input() data: FilterDefaultOptions;

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  get text(): AbstractControl {
    return this.inputForm.get('text');
  }

  ngOnInit() {
    this.inputForm = new UntypedFormGroup({
      text: new UntypedFormControl('', [])
    });
  }

  onInputValidation(): void {
    const filter: Filter = {};
    filter.value = this.text.value.trim();
    filter.label = this.text.value.trim();
    filter.criteriaKey = this.data.criteriaKey;
    filter.criteriaLabel = this.data.criteriaLabel;
    this.dispatchFilterValue.emit(filter);
    this.text.reset();
  }
}
