import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Log } from '@iot-platform/models/i4b';

import { Observable, of } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-event-comments',
  templateUrl: './event-comments.component.html',
  styleUrls: ['./event-comments.component.scss']
})
export class EventCommentsComponent implements OnInit, OnChanges, AfterViewChecked {
  form: UntypedFormGroup;
  logs: Log[] = [];

  @Input() displayMode = 'side-panel';
  @Input() logsByEvent: Log[];
  @Input() logsByAssetEvent: Log[];
  @Input() logsByDeviceEvent: Log[];
  @Input() eventType = '';
  @Input() canUpdateEvent: boolean;
  @Input() resetLogs$: Observable<boolean> = of(null);

  @Output() addComment: EventEmitter<string> = new EventEmitter();
  @Output() closeComments: EventEmitter<any> = new EventEmitter();

  get comment(): AbstractControl {
    return this.form.get('comment');
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl(null, [Validators.maxLength(300)])
    });
    this.resetLogs$.subscribe((reset) => {
      if (reset) {
        this.logs = [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('logsByEvent') && changes.logsByEvent.currentValue) {
      this.logs = changes.logsByEvent.currentValue.filter((log) => log.isComment);
    }

    if (this.eventType === 'asset-events' && changes.hasOwnProperty('logsByAssetEvent') && changes.logsByAssetEvent.currentValue) {
      this.logs = [...changes.logsByAssetEvent.currentValue.filter((log) => log.isComment)];
    }

    if (this.eventType === 'device-events' && changes.hasOwnProperty('logsByDeviceEvent') && changes.logsByDeviceEvent.currentValue) {
      this.logs = [...changes.logsByDeviceEvent.currentValue.filter((log) => log.isComment)];
    }
  }

  ngAfterViewChecked() {
    this.scrollHandler();
  }

  onAddComment() {
    if (this.form.valid && this.comment.value) {
      this.addComment.emit(this.comment.value);
      this.comment.reset();
    }
  }

  onCloseComments() {
    this.closeComments.emit();
    this.logs = [];
  }

  private scrollHandler() {
    let element: HTMLElement;
    if (this.displayMode === 'side-panel') {
      element = document.querySelector('.event-comments-content');
    }

    if (this.displayMode === 'detail-popup') {
      element = document.querySelector('.cdk-overlay-container .event-comments-content');
    }

    element.classList.remove('not-scrolled');
    if (element.scrollHeight <= element.offsetHeight) {
      element.classList.add('not-scrolled');
    } else {
      element.scrollTop = element.scrollHeight;
    }
  }
}
