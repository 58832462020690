import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { DateFormatModule, IotPlatformPipesModule } from '@iot-platform/pipes';
import { IconModule } from '@iot-platform/shared/components';
import { DynamicListFieldService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { noop, of } from 'rxjs';
import { AsyncAutocompleteMultipleSelectsModule } from '../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from '../async-autocomplete/async-autocomplete.module';
import { ChipModule } from '../chip/chip.module';
import { CountryAutocompleteModule } from '../country-autocomplete/country-autocomplete.module';
import { DateRangeModule } from '../date-range/date-range.module';
import { TimezoneAutocompleteMultipleSelectsModule } from '../timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { TimezoneAutocompleteModule } from '../timezone-autocomplete/timezone-autocomplete.module';
import { CountryAutocompleteFieldComponent } from './country-autocomplete-field/country-autocomplete-field.component';
import { DateIntervalFieldComponent } from './date-field/date-interval-field.component';
import { DynamicListFieldMultipleSelectsComponent } from './dynamic-list-field/multiple-selects/dynamic-list-field-multiple-selects.component';
import { DynamicListFieldSingleSelectComponent } from './dynamic-list-field/single-select/dynamic-list-field-single-select.component';
import { FavoriteFilterEngineDirective } from './favorite-filter-engine.directive';
import { AbstractFavoriteFilterEngineService } from './favorite-filter-engine/abstract-favorite-filter-engine.service';
import { FavoriteFilterEngineComponent } from './favorite-filter-engine/favorite-filter-engine.component';
import { FilterComponentFactory } from './filter-component-factory';
import { FilterEngineSettingsService } from './filter-engine-settings.service';
import { FilterEngineComponent } from './filter-engine.component';
import { FilterEngineDirective } from './filter-engine.directive';
import { FilterEngineService } from './filter-engine.service';
import { InputFieldComponent } from './input-field/input-field.component';
import { MultipleInputsFieldComponent } from './input-field/multiple-inputs-field/multiple-inputs-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { TimezoneAutocompleteFieldComponent } from './timezone-autocomplete-field/timezone-autocomplete-field.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    ChipModule,
    IotPlatformPipesModule,
    TranslateModule,
    IconModule,
    DateFormatModule,
    MatProgressSpinnerModule,
    DateRangeModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatTooltipModule
  ],
  exports: [FilterEngineComponent, FavoriteFilterEngineComponent],
  declarations: [
    FilterEngineComponent,
    FavoriteFilterEngineComponent,
    FilterEngineDirective,
    FavoriteFilterEngineDirective,
    InputFieldComponent,
    MultipleInputsFieldComponent,
    SelectFieldComponent,
    DateIntervalFieldComponent,
    DynamicListFieldSingleSelectComponent,
    DynamicListFieldMultipleSelectsComponent,
    TimezoneAutocompleteFieldComponent,
    CountryAutocompleteFieldComponent
  ],
  providers: [FilterEngineService, FilterComponentFactory, DynamicListFieldService, SortEntitiesUtil, FilterEngineSettingsService]
})
export class FilterEngineModule {
  static withSettings(settings: { favoriteFilterEngineService: Type<AbstractFavoriteFilterEngineService> }): ModuleWithProviders<FilterEngineModule> {
    return {
      ngModule: FilterEngineModule,
      providers: [{ provide: AbstractFavoriteFilterEngineService, useClass: settings.favoriteFilterEngineService }]
    };
  }

  static withTestingSettings(): ModuleWithProviders<FilterEngineModule> {
    return {
      ngModule: FilterEngineModule,
      providers: [
        {
          provide: AbstractFavoriteFilterEngineService,
          useValue: {
            saveUserPreferences: () => noop(),
            getAccount$: () => of({} as UserAccount),
            getUserPreferences$: () => of({} as UserPreferences)
          }
        }
      ]
    };
  }
}
