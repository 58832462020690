import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-add-something',
  templateUrl: './add-something.component.html',
  styleUrls: ['./add-something.component.scss']
})
export class AddSomethingComponent {
  @Input() text = 'something';
}
