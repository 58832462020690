import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCommonModule, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ProgressBarButtonModule
} from '../../../iot-platform-ui/src/lib/ui/components/progress-bar-button/progress-bar-button.module';
import { AUTH_CONFIG, AuthConfig } from './auth.config';

import { AuthInterceptor } from './auth.interceptor';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import {
  LoginChangeTemporaryPasswordFormComponent
} from './components/login-change-temporary-password-form/login-change-temporary-password-form.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPasswordRulesComponent } from './components/login-password-rules/login-password-rules.component';
import {
  LoginResetPasswordFormComponent
} from './components/login-reset-password-form/login-reset-password-form.component';
import {
  LoginSelectAuthenticationComponent
} from './components/login-select-authentication/login-select-authentication.component';
import { LoginSelectProfileModule } from './components/login-select-profile/login-select-profile.module';
import { LoginSendCodeFormComponent } from './components/login-send-code-form/login-send-code-form.component';
import { LoginShellComponent } from './containers/login-shell/login-shell.component';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { AuthApiActions } from './state/actions';
import { AuthEffects } from './state/effects/auth.effects';
import { AuthBusinessProfilesEffects } from './state/effects/business-profiles.effects';
import * as fromAuth from './state/reducers';

const MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatCommonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
];

const SHARED_MODULES = [FormsModule, ReactiveFormsModule, FlexLayoutModule];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([AuthEffects, AuthBusinessProfilesEffects]),
    TranslateModule,
    ProgressBarButtonModule,
    RouterModule,
    MATERIAL_MODULES,
    SHARED_MODULES,
    LoginSelectProfileModule
  ],
  declarations: [
    LoginResetPasswordFormComponent,
    LoginFormComponent,
    LoginSendCodeFormComponent,
    LoginShellComponent,
    LoginChangeTemporaryPasswordFormComponent,
    LoginPasswordRulesComponent,
    LoginCallbackComponent,
    LoginSelectAuthenticationComponent
  ],
  exports: [
    TranslateModule,
    LoginSelectProfileModule,
    LoginResetPasswordFormComponent,
    LoginFormComponent,
    LoginSendCodeFormComponent,
    LoginShellComponent,
    LoginChangeTemporaryPasswordFormComponent,
    LoginPasswordRulesComponent,
    LoginSelectAuthenticationComponent
  ],
  providers: [AuthGuard, TranslateService]
})
export class AuthModule {
  constructor(private store: Store, private storage: LocalStorageService) {
    const session = this.storage.get(LocalStorageKeys.STORAGE_SESSION_KEY);
    const initialState = {
      loggedIn: session ? JSON.parse(session).loggedIn : false,
      tokenExpired: session ? JSON.parse(session).tokenExpired : true,
      idToken: session ? JSON.parse(session).idToken : null,
      accessToken: session ? JSON.parse(session).accessToken : null,
      refreshToken: session ? JSON.parse(session).refreshToken : null,
      currentUser: session ? JSON.parse(session).currentUser : null,
      cognitoUser: session ? JSON.parse(session).cognitoUser : null,
      userId: session ? JSON.parse(session).userId : null,
      firstLogIn: session ? JSON.parse(session).firstLogIn : false,
      businessProfiles: session ? JSON.parse(session).businessProfiles : null,
      selectedBusinessProfile: session ? JSON.parse(session).selectedBusinessProfile : null,
      currentEntity: session ? JSON.parse(session).currentEntity : null,
      privileges: session ? JSON.parse(session).privileges : null,
      preferences: session ? JSON.parse(session).preferences : null,
      isUserAdmin: false,
      loggedOut: false
    };
    this.store.dispatch(AuthApiActions.setInitialStateFromStore({initialState}));
  }

  static forRoot(config?: Partial<AuthConfig>): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthService, {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }, {provide: AUTH_CONFIG, useValue: config}]
    };
  }
}
