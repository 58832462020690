import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceInfoStatusComponent } from './device-info-status.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [CommonModule, MatCardModule, MatIconModule, FlexLayoutModule, IotPlatformPipesModule, TranslateModule],
  declarations: [DeviceInfoStatusComponent],
  exports: [DeviceInfoStatusComponent]
})
export class DeviceInfoStatusModule {}
