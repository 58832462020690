import { createReducer, on } from '@ngrx/store';
import { AuthApiActions, AuthPageActions } from '../actions';

export const authPageFeatureKey = 'authPage';

export interface State {
  error: any;
  pending: boolean;
  stepEmail: boolean;
  stepPassword: boolean;
  errorSignIn: boolean;
  errorEmail: boolean;
  errorPassword?: string;
  requireNewPassword: boolean;
  signOutPending: boolean;
  accountLoading: boolean;
  displayEmailPasswordLogin: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  stepEmail: false,
  stepPassword: false,
  errorSignIn: false,
  errorEmail: false,
  errorPassword: undefined,
  requireNewPassword: false,
  signOutPending: false,
  accountLoading: false,
  displayEmailPasswordLogin: false
};

export const reducer = createReducer(
  initialState,
  on(AuthPageActions.signIn, AuthPageActions.loadAccount, (state: State) => ({ ...state, error: null, pending: true, errorSignIn: false })),
  on(AuthApiActions.signInSuccess, AuthApiActions.loadAccountSuccess, (state: State) => ({
    ...state,
    error: null,
    pending: false,
    errorSignIn: false,
    displayEmailPasswordLogin: false
  })),
  on(AuthApiActions.signInFailure, (state: State, { error }) => ({
    ...state,
    error,
    errorSignIn: error,
    pending: false
  })),
  on(AuthPageActions.signOut, (state: State) => ({
    ...state,
    error: null,
    pending: true,
    signOutPending: true,
    stepEmail: false
  })),
  on(AuthApiActions.signOutSuccess, AuthApiActions.signOutFailure, (state: State) => ({
    ...state,
    signOutPending: false
  })),
  on(AuthPageActions.cancel, (state: State) => ({
    ...state,
    error: null,
    pending: false,
    stepEmail: false,
    stepPassword: false,
    errorSignIn: false,
    errorEmail: false,
    errorPassword: undefined,
    requireNewPassword: false,
    displayEmailPasswordLogin: false
  })),
  on(AuthPageActions.resetPassword, (state: State) => ({
    ...state,
    stepEmail: true,
    stepPassword: false,
    errorSignIn: false,
    displayEmailPasswordLogin: false
  })),
  on(AuthPageActions.changePassword, (state: State) => ({ ...state })),
  on(AuthApiActions.changePasswordSuccess, (state: State) => ({
    ...state,
    requireNewPassword: false
  })),
  on(AuthPageActions.forgotPassword, (state: State) => ({
    ...state,
    stepEmail: false,
    stepPassword: true,
    displayEmailPasswordLogin: false
  })),
  on(AuthPageActions.updatePasswordWithCode, (state: State) => ({
    ...state,
    errorPassword: undefined
  })),
  on(AuthApiActions.updatePasswordWithCodeSuccess, (state: State) => ({
    ...state,
    stepEmail: false,
    stepPassword: false,
    displayEmailPasswordLogin: false
  })),
  on(AuthApiActions.updatePasswordWithCodeFailure, (state: State, error) => ({
    ...state,
    errorPassword: error.error
  })),
  on(AuthPageActions.requireNewPassword, (state: State) => ({
    ...state,
    requireNewPassword: true
  })),
  on(AuthApiActions.retrieveSessionFailure, () => initialState),
  on(AuthPageActions.loadAccount, (state: State) => ({
    ...state,
    accountLoading: true
  })),
  on(AuthApiActions.loadAccountSuccess, (state: State) => ({
    ...state,
    accountLoading: false
  })),
  on(AuthApiActions.loadAccountFailure, (state: State) => ({
    ...state,
    accountLoading: false
  })),
  on(AuthPageActions.displayEmailPasswordLogin, (state: State) => ({
    ...state,
    displayEmailPasswordLogin: true
  }))
);

export const getPending = (state: State) => state.pending;
export const getStepEmail = (state: State) => state.stepEmail;
export const getStepPassword = (state: State) => state.stepPassword;
export const getRequiredNewPassword = (state: State) => state.requireNewPassword;
export const getDisplayEmailPasswordLogin = (state: State) => state.displayEmailPasswordLogin;

export const getError = (state: State) => state.error;
export const getErrorSignIn = (state: State) => state.errorSignIn;
export const getErrorEmail = (state: State) => state.errorEmail;
export const getErrorPassword = (state: State) => state.errorPassword;

export const getAccountLoading = (state: State) => state.accountLoading;
