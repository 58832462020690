import { Injectable } from '@angular/core';
import { UserAccount, UserPreferences } from '@iot-platform/models/common';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable, tap } from 'rxjs';
import { AbstractFavoriteFilterEngineService } from './favorite-filter-engine/abstract-favorite-filter-engine.service';

@Injectable()
export class FilterEngineSettingsService extends ComponentStore<object> {
  account!: UserAccount;
  account$: Observable<UserAccount> = this.favoriteFilterEngineService.getAccount$();
  userPreferences!: UserPreferences;
  userPreferences$: Observable<UserPreferences> = this.favoriteFilterEngineService.getUserPreferences$();
  private readonly init$ = this.effect(($trigger) =>
    $trigger.pipe(
      concatLatestFrom(() => [this.favoriteFilterEngineService.getAccount$(), this.favoriteFilterEngineService.getUserPreferences$()]),
      tap(([_, account, userPreferences]) => {
        this.account = account;
        this.userPreferences = userPreferences;
      })
    )
  );

  constructor(private readonly favoriteFilterEngineService: AbstractFavoriteFilterEngineService) {
    super();
    this.init$();
  }

  saveUserPreferences(user: UserAccount, preferences: UserPreferences): void {
    return this.favoriteFilterEngineService.saveUserPreferences(user, preferences);
  }
}
