import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_CONFIG, AuthConfig, AuthService, LoginShellComponent } from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthFacade } from '../../state/facades/auth.facade';

@Component({
  selector: 'qlixbi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends LoginShellComponent {
  bpList$: Observable<BusinessProfile[]> = this.businessProfiles$.pipe(
    map((businessProfiles: BusinessProfile[]) => businessProfiles ?? []),
    map((businessProfiles: BusinessProfile[]) => businessProfiles.map((bp: BusinessProfile) => ({
        ...bp,
        entityName: bp.entityAltName ?? bp.entityName
      })))
  );

  constructor(
    private readonly ref: ChangeDetectorRef,
    protected authService: AuthService,
    protected store: Store,
    protected translateService: TranslateService,
    @Inject(AUTH_CONFIG) protected config: AuthConfig,
    private readonly authFacade: AuthFacade,
    private readonly router: Router
  ) {
    super(authService, store, translateService, config);
  }

  public ssoLogin(): void {
    this.authFacade.signInSso();
  }

  public backToLogin(): void {
    this.router.navigate(['/login']);
  }
}
