import { Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Concept, Permission } from '@iot-platform/models/qlixbi';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(
    private readonly permissionsService: NgxPermissionsService,
    private readonly storage: LocalStorageService
  ) {}

  get permissions$(): Observable<NgxPermissionsObject> {
    return this.permissionsService.permissions$;
  }

  init(): void {
    const privileges = this.storage.get(LocalStorageKeys.STORAGE_PRIVILEGES_KEY);
    if (privileges) {
      const rights: { [key: string]: Permission[] } = JSON.parse(privileges);
      const permissions: string[] = Object.keys(rights).reduce((acc: string[], concept: Concept) => {
        const arr: string[] = rights[concept].map((permission: Permission) => `${concept}_${permission}`);
        return [...acc, ...arr];
      }, []);
      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(permissions);
    }
  }

  hasPermissions(permissions: string[][]): Observable<{ key: string; value: boolean }[]> {
    return this.permissions$.pipe(
      map((ngxPermissionsObject: NgxPermissionsObject) =>
        permissions.reduce(
          (acc: { key: string; value: boolean }[], [key, value]) => [
            ...acc,
            {
              key,
              value: !!ngxPermissionsObject[value]
            }
          ],
          []
        )
      )
    );
  }

  hasPermission(permission: string): Observable<boolean> {
    return from(this.permissionsService.hasPermission(permission));
  }
}
