<div class="login-shell default-theme">
  <div class="login-shell-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%"></div>
    <div class="login-shell-col" fxFlex="50%" fxLayout="column" fxFlex.lt-md="100%">
      <h1 class="login-shell-title">IOT-4BOS</h1>

      <h2>{{ 'LOGIN.AUTH_IN_PROGRESS' | translate }}</h2>
      <div fxLayout="column" style="height: 100%" fxLayoutAlign="center center">
        <mat-progress-spinner mode="indeterminate" [diameter]="90" color="accent"></mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
