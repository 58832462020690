import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AnalyticsService } from '@iot-platform/core';
import { PopupComponent } from 'libs/iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { PopupModule } from '../../../iot-platform-ui/src/lib/ui/components/popup/popup.module';
import { LoaderComponent } from './loader/loader.component';

import { NotificationService } from './notification.service';

export const DISABLE_ERROR_MESSAGES_AFTER_LOGOUT = new InjectionToken<boolean>('DISABLE_ERROR_MESSAGES_AFTER_LOGOUT');

@Component({
  standalone: true,
  imports: [AsyncPipe, LoaderComponent, MatSnackBarModule, MatDialogModule, PopupModule],
  selector: 'iot-platform-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('display_error_popup');
  loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedOut!: boolean;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    readonly snackBar: MatSnackBar,
    readonly notificationService: NotificationService,
    readonly dialog: MatDialog,
    @Optional() @Inject(DISABLE_ERROR_MESSAGES_AFTER_LOGOUT) private disableErrorMessagesAfterLogout: boolean
  ) {}

  @Input() set isLoggedOut(val: boolean) {
    if (this.disableErrorMessagesAfterLogout) {
      this.loggedOut = val;
    }
  }

  ngOnInit(): void {
    this.notificationService.loader$.pipe(delay(0), takeUntil(this.unsubscribe$)).subscribe((loader: boolean) => this.loader$.next(loader));
    this.notificationService.error$
      .pipe(delay(0), takeUntil(this.unsubscribe$))
      .subscribe((value: string | { code: string; error: string }) => this.displayError(value));
    this.notificationService.success$.pipe(delay(0), takeUntil(this.unsubscribe$)).subscribe((value: string) => this.displaySuccess(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  public openErrorPopup(value: string | { code: string; error: string } | any) {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty('type')) {
      this.analytic.logError(
        value.type,
        `${value.error.error.code} - ${value.error.error.error}`,
        `${value.type} : ${value.error.error.code} - ${value.error.error.error}`
      );
    }
    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'error', value }
    });
  }

  private displaySuccess(message: string, action = '', duration = 4000) {
    this.snackBar.open(message, action, { duration });
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  private displayError(error: string | { code: string; error: string } | any) {
    if (this.disableErrorMessagesAfterLogout) {
      if (this.loggedOut === false) {
        this.openErrorPopup(error);
      }
    } else {
      this.openErrorPopup(error);
    }
  }
}
