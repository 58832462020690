import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateFormatModule } from '@iot-platform/pipes';

import { IconModule } from '@iot-platform/shared/components';

import { TranslateModule } from '@ngx-translate/core';
import { ChipComponent } from './chip.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatChipsModule, IconModule, TranslateModule, DateFormatModule, FlexLayoutModule],
  declarations: [ChipComponent],
  exports: [ChipComponent]
})
export class ChipModule {}
