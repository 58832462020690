import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadCardComponent } from './download-card.component';

@NgModule({
  declarations: [DownloadCardComponent],
  exports: [DownloadCardComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, TranslateModule, MatButtonModule, MatProgressSpinnerModule]
})
export class DownloadCardModule {}
