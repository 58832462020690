import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { UserAccount } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-users-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent implements OnInit {
  userProfileForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<UserProfileFormComponent>, @Inject(MAT_DIALOG_DATA) public data: { user: UserAccount }) {}

  ngOnInit() {
    this.userProfileForm = new UntypedFormGroup({
      firstname: new UntypedFormControl(this.data.user && this.data.user.firstname ? this.data.user.firstname.trim() : null, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern('\\S.*')
      ]),
      lastname: new UntypedFormControl(this.data.user && this.data.user.lastname ? this.data.user.lastname.trim() : null, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern('\\S.*')
      ]),
      email: new UntypedFormControl(this.data.user ? { value: this.data.user.email, disabled: true } : { value: null, disabled: true }, [
        Validators.maxLength(30)
      ]),
      secondaryEmail: new UntypedFormControl(this.data.user && this.data.user.secondaryEmail ? this.data.user.secondaryEmail : null, [
        Validators.maxLength(50)
      ]),
      phoneNumber: new UntypedFormControl(this.data.user && this.data.user.phoneNumber ? this.formatPhoneNumber(this.data.user.phoneNumber) : null, [
        Validators.pattern('^[0-9]*$')
      ]),
      uuid: new UntypedFormControl(this.data.user ? { value: this.data.user.id, disabled: true } : { value: null, disabled: true })
    });
  }

  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber.trim()[0] === '+' ? phoneNumber.trim().substring(1) : phoneNumber.trim();
  }

  get firstname(): AbstractControl {
    return this.userProfileForm.get('firstname');
  }

  get lastname(): AbstractControl {
    return this.userProfileForm.get('lastname');
  }

  get secondaryEmail(): AbstractControl {
    return this.userProfileForm.get('secondaryEmail');
  }

  get phoneNumber(): AbstractControl {
    return this.userProfileForm.get('phoneNumber');
  }

  save(): void {
    const updated: UserAccount = {
      ...this.data.user,
      firstname: this.firstname.value,
      lastname: this.lastname.value,
      secondaryEmail: this.secondaryEmail.value,
      phoneNumber: this.phoneNumber.value ? '+' + this.phoneNumber.value : null
    };

    this.dialogRef.close(updated);
  }

  exit() {
    this.dialogRef.close();
  }
}
