import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthModule } from '@iot-platform/auth';
import { ApiInterceptorModule, LocalStorageModule } from '@iot-platform/core';
import { I18nModule } from '@iot-platform/i18n';
import { NotificationModule } from '@iot-platform/notification';
import { PermissionsService } from '@iot-platform/qlixbi-ui';
import { PreferencesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('@iot-platform/qlixbi-ui').then((m) => m.QlixbiUiModule) },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    LocalStorageModule.forRoot({ prefix: environment.storage.appPrefix }),
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false }),
    I18nModule.forRoot({ langs: ['fr', 'en'] }),
    I18nModule.getInstance(),
    BrowserModule,
    NotificationModule,
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false
        }
      }
    ),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 20 }) : [],
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    EffectsModule.forRoot([]),
    NgxMaterialTimepickerModule,
    AuthModule.forRoot(),
    PreferencesModule,
    ApiInterceptorModule.forRoot({
      excludedApis: ['/account/privileges', '/admin-profiles', '/directory/users']
    }),
    NgxIndexedDBModule.forRoot({
      name: `${environment.storage.appPrefix}_idb`.toUpperCase(),
      version: 1,
      objectStoresMeta: [
        {
          store: 'mv_filters',
          storeConfig: { keyPath: 'mv_name', autoIncrement: false },
          storeSchema: [
            { name: 'filters', keypath: 'filters', options: { unique: false } },
            { name: 'searchQuery', keypath: 'searchQuery', options: { unique: false } }
          ]
        }
      ]
    })
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    {
      provide: APP_INITIALIZER,
      useFactory: (permissionsService: PermissionsService) =>
        function () {
          permissionsService.init();
        },
      deps: [PermissionsService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
