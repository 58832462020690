export class SortUtil {
  static sortByName = (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0);

  static sortByOrder = (a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0);

  static sortByKey = (a, b) => (a.key.toLowerCase() > b.key.toLowerCase() ? 1 : b.key.toLowerCase() > a.key.toLowerCase() ? -1 : 0);

  static sortByProperty = (property: string) => (a, b) => {
      if (typeof a[property] === 'string') {
        return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : a[property].toLowerCase() < b[property].toLowerCase() ? -1 : 0;
      } else {
        return a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0;
      }
    };

  static sortBy = (property: string, ascending) => (a, b) => {
    if (!a[property]) {
      return ascending ? 1 : -1;
    }
    if (!b[property]) {
      return ascending ? -1 : 1;
    }
    if (typeof a[property] === 'string') {
      if (ascending) {
        return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
      }
      return a[property].toLowerCase() > b[property].toLowerCase() ? -1 : 1;
    } else {
      if (ascending) {
        return a[property] > b[property] ? 1 : -1;
      }
      return a[property] > b[property] ? -1 : 1;
    }
  };

  static sortByLevelNameName = (a, b) =>
    a.entity.level > b.entity.level
      ? 1
      : a.entity.level < b.entity.level
      ? -1
      : a.entity.name.toLowerCase() > b.entity.name.toLowerCase()
      ? 1
      : a.entity.name.toLowerCase() < b.entity.name.toLowerCase()
      ? -1
      : a.name.toLowerCase() > b.name.toLowerCase()
      ? 1
      : a.name.toLowerCase() < b.name.toLowerCase()
      ? -1
      : 0;

  static sortByOrderThenName = (a, b) =>
    a.order > b.order ? 1 : a.order < b.order ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
}
