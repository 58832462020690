import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { YearPickerComponent } from './year-picker.component';

@NgModule({
  declarations: [YearPickerComponent],
  exports: [YearPickerComponent],
  imports: [CommonModule, TranslateModule, FlexLayoutModule, ReactiveFormsModule, MatDatepickerModule, MatSelectModule, MatIconModule, MatInputModule]
})
export class YearPickerModule {}
