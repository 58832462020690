import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupDisplayPropertyComponent } from './detail-popup-display-property/detail-popup-display-property.component';
import { DetailPopupMetadataFooterComponent } from './detail-popup-metadata-footer/detail-popup-metadata-footer.component';
import { DetailPopupSectionHeaderComponent } from './detail-popup-section-header/detail-popup-section-header.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [CommonModule, FlexModule, IotPlatformPipesModule, TranslateModule, MatProgressSpinnerModule],
  declarations: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent],
  exports: [DetailPopupSectionHeaderComponent, DetailPopupDisplayPropertyComponent, DetailPopupMetadataFooterComponent]
})
export class DetailPopupModule {}
